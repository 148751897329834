import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/G3Logo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import NewContributor from "./ContactHub/NewContributor";
import ContactPost from "./ContactHub/ContactPost";
import { useLocation, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faHome } from "@fortawesome/free-solid-svg-icons";
import { AddCandidateModal } from "./BuildingBlocks/AddCandidateModal";

export default function NavbarMain(props) {
  //const [search, setSearch] = useState("")
  const [activeKey, setActive] = useState();
  const location = useLocation();
  const history = useHistory();

  function handleSelect(eventKey, event) {
    setActive(eventKey);
  }

  return (
    <Navbar
      style={{ height: "auto", minWidth: 510 }}
      bg="primary"
      variant="dark"
      expand="lg"
      sticky="top"
    >
      <div className="container-fluid">
        <Navbar.Brand onClick={() => history.push("/")}>
          <img
            src={Logo}
            alt="Good Government Group"
            style={{ height: 50, marginTop: 7 }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link>G3 Hub</Nav.Link>
          </Nav>
          <Nav
            activeKey={activeKey}
            onSelect={handleSelect}
            fill
            className="mx-auto"
            style={{ width: "300px" }}
          >
            <Nav.Link
              as={NavLink}
              eventKey={1}
              to="../home"
              activeStyle={{
                borderBottom: "3px solid #fcfcfc",
                color: "#fcfcfc",
              }}
            >
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{"Home"}</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  color={location.pathname !== "/home" ? "#fcfcfc" : "#fcfcfc"}
                  size="2x"
                />
              </OverlayTrigger>
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              eventKey={1}
              to="../calltime"
              activeStyle={{
                borderBottom: "3px solid #fcfcfc",
                color: "#fcfcfc",
              }}
            >
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{"Call Time"}</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  color={
                    location.pathname !== "/calltime" ? "#fcfcfc" : "#fcfcfc"
                  }
                  size="2x"
                />
              </OverlayTrigger>
            </Nav.Link>
          </Nav>
          <Nav>
            <AddCandidateModal btnVariant={"outline-light"} /> <ContactPost />
            <Form>
              <Form.Control type="text" placeholder="Search..." />
            </Form>
          </Nav>
          <NavDropdown align="end" className="nav navbar-nav">
            <NavDropdown.Item onClick={props.onClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

//const { user } = this.props.auth;

//<NavDropdown.Item onClick={this.props.onClick}>Logout</NavDropdown.Item>
