import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCallPhone, setCallTime } from "../../actions/callTimeActions";
import {
  addContactToList,
  setContact,
  setList,
} from "../../actions/queryActions";
import { address } from "../../utils/connectionAddress";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { toTitleCase } from "../../utils/toTitleCase";
import { ActionBadgeBase } from "../BuildingBlocks/ActionBadges";
import { ResuableBreadcrumbs } from "../BuildingBlocks/Breadcrumbs";
import CallsTable from "../BuildingBlocks/CallsTable";
import DonationTable from "../BuildingBlocks/DonationTable";
import { QuickFind } from "../BuildingBlocks/QuickFind";
import NavbarMain from "../Navbar";
import { PostCalls } from "./PostCalls";

export const CT_Session = ({ match }, props) => {
  const list = useSelector((state) => state.gets.list);
  const contact = useSelector((state) => state.gets.contact);
  const dispatch = useDispatch();
  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  useEffect(() => {
    setUpCallTime(match.params.call_time_id);
  }, []);

  const setUpCallTime = async (id) => {
    try {
      const callTime = await axios.get(address + `/call_times/${id}`);
      dispatch(setCallTime(callTime.data));
      const list = await axios.get(address + `/lists/${callTime.data.listId}`);
      dispatch(setList(list.data));
      const firstContact = await axios.get(
        address + `/contacts/${list.data.contacts[0]}`
      );
      dispatch(setContact(firstContact.data));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <NavbarMain onClick={onLogoutClick} />
      <div className="container-fluid">
        <div
          style={{ height: "5vh", marginTop: "2vh" }}
          className="container-fluid"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="text-left">
                <ResuableBreadcrumbs
                  array={[
                    { value: "Schedule", href: "calltime" },
                    { value: "Call", active: true },
                    { value: "Review" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5">
            <ListDisplayControl list={list} />
            <CallTimeStats />
          </div>
          <div className="col-md-7">
            <div className="row"> {contact ? <PostCalls /> : <></>}</div>
            <div className="row"> {contact ? <ContactDisplay /> : <></>}</div>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

export const ListDisplayControl = ({ list }) => {
  const callTime = useSelector((state) => state.calls.callTime);
  const dispatch = useDispatch();
  const callback = async (e, contacts) => {
    e.preventDefault();
    if (Array.isArray(contacts)) {
      for (let i = 0; i < contacts.length; i++) {
        try {
          await axios.post(
            `${address}/lists/${list.id}/contacts/${contacts[i].id}`
          );
        } catch (err) {
          console.log(err);
        }
        dispatch(addContactToList(contacts[i].id));
      }
    }
  };

  return (
    <div className="card bg-secondary text-white">
      <div className="container-fluid">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-md-5">
              <h4>
                List name: {list ? list.name : "This call time does not work"}
              </h4>
              <div className="card-text">
                This call time is for:{" "}
                <em>
                  {callTime && callTime.candidate
                    ? callTime.candidate.name
                    : ""}
                </em>
              </div>
            </div>
            <div className="col-md-7">
              <div className="form-group row">
                <div className="col">
                  <label for="quickAdd">Quick add to list:</label>
                </div>
                <QuickFind
                  multi={true}
                  setPropCallback={callback}
                  icon={faPlusCircle}
                />
              </div>
            </div>
          </div>
          <div className="row small" style={{ marginBottom: "1vh" }}>
            <div className="col">
              <hr />
              <div className="card-text text-decoration-underline">
                List Stats:
              </div>
              <div className="card-text">
                {list ? list.contacts.length : ""}{" "}
                {list && list.contacts.length === 1 ? "person" : ""}
                {list && list.contacts.length > 1 ? "people" : ""}
              </div>
            </div>
          </div>
          <ListSummary />
        </div>
      </div>
    </div>
  );
};

const ListSummary = () => {
  const [pledgeSummary, setPledgeSummary] = useState();
  const [donationSummaryTotal, setDonationSummaryTotal] = useState();
  const [donationSummaryCandidate, setDonationSummaryCandidate] = useState();
  const callTime = useSelector((state) => state.calls.callTime);
  const list = useSelector((state) => state.gets.list);

  useEffect(() => {
    getPledgeSummary();
    getDonationSummaryTotal();
    getDonationSummaryCandidate();
  }, [callTime, list]);

  const getPledgeSummary = async () => {
    if (callTime && list) {
      try {
        const pledgeSummary = await axios.get(
          address +
            "/pledges/summary?contact_ids[]=" +
            list.contacts.join("&contact_ids[]=") +
            `&candidate_id=${callTime.candidateId}`
        );
        setPledgeSummary(pledgeSummary.data);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getDonationSummaryTotal = async () => {
    if (callTime && list) {
      try {
        const donationSummary = await axios.get(
          address +
            "/donations/summary?contact_ids[]=" +
            list.contacts.join("&contact_ids[]=")
        );
        setDonationSummaryTotal(donationSummary.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getDonationSummaryCandidate = async () => {
    if (callTime && list) {
      try {
        const donationSummary = await axios.get(
          address +
            "/donations/summary?contact_ids[]=" +
            list.contacts.join("&contact_ids[]=") +
            `&candidate_id=${callTime.candidateId}`
        );
        setDonationSummaryCandidate(donationSummary.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <small className="text-decoration-underline">
        Pledges in last 3 months:
      </small>
      {pledgeSummary ? (
        <div className="row small" style={{ marginBottom: "1vh" }}>
          {pledgeSummary._count === 0 ? (
            <div className="col">No Pledges Found</div>
          ) : (
            <>
              {pledgeSummary._sum.amount !== null ? (
                <div className="col">
                  Pledges: ${pledgeSummary._sum.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              {pledgeSummary._avg.amount !== null ? (
                <div className="col">
                  Avg. Pledge: ${pledgeSummary._avg.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              <div className="col">
                Number of Pledges: {pledgeSummary._count}
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      <small className="text-decoration-underline">Donations total:</small>
      {donationSummaryTotal ? (
        <div className="row small" style={{ marginBottom: "1vh" }}>
          {donationSummaryTotal._count === 0 ? (
            <div className="col">No Donations Found</div>
          ) : (
            <>
              {donationSummaryTotal._sum.amount !== null ? (
                <div className="col">
                  Donations: ${donationSummaryTotal._sum.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              {donationSummaryTotal._avg.amount !== null ? (
                <div className="col">
                  Avg. Donation: ${donationSummaryTotal._avg.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              <div className="col">
                Number of Donations: {donationSummaryTotal._count}
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      <small className="text-decoration-underline">
        Donations to{" "}
        {callTime && callTime.candidate ? callTime.candidate.name : ""}:
      </small>
      {donationSummaryCandidate ? (
        <div className="row small" style={{ marginBottom: "1vh" }}>
          {donationSummaryCandidate._count === 0 ? (
            <div className="col">No Donations Found</div>
          ) : (
            <>
              {donationSummaryCandidate._sum.amount !== null ? (
                <div className="col">
                  Donations: ${donationSummaryCandidate._sum.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              {donationSummaryCandidate._avg.amount !== null ? (
                <div className="col">
                  Avg. Donation: $
                  {donationSummaryCandidate._avg.amount.toFixed(2)}
                </div>
              ) : (
                <></>
              )}
              <div className="col">
                Number of Donations: {donationSummaryCandidate._count}
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const ContactDisplay = () => {
  const contact = useSelector((state) => state.gets.contact);
  const { phones, employer, occupation, name } = contact;

  return (
    <div className="card">
      {contact ? (
        <div className="card-body">
          <h4 className="card-title">Contact Details:</h4>
          <div className="container-fluid">
            <div className="row align-items-center">
              <InfoDisplaySection propToDisplay={name} propName={"Name"} />
              {[
                {
                  propToDisplay: phones,
                  propName: "Phone",
                  title: "Phone Numbers",
                },
              ].map((item) => {
                return (
                  <InfoDisplaySection
                    propToDisplay={item.propToDisplay}
                    propName={item.propName}
                  />
                );
              })}
              <InfoDisplaySection
                propToDisplay={employer}
                propName={"Employer"}
              />
              <InfoDisplaySection
                propToDisplay={occupation}
                propName={"Occupation"}
              />
            </div>
            <hr />
            <div className="row align-items-center">
              <h5 className="card-title text-decoration-underline">
                Donations:
              </h5>
              <DonationTable height="35vh" />
            </div>
            <hr />
            <div className="row align-items-center">
              <h5 className="card-title text-decoration-underline">
                Previous Calls:
              </h5>
              <CallsTable height="35vh" />
            </div>
          </div>
        </div>
      ) : (
        <>Contact not found. Ask your site administrator for help.</>
      )}
    </div>
  );
};

const InfoDisplaySection = ({ propToDisplay, propName }) => {
  const phone = useSelector((state) => state.calls.phone);

  const dispatch = useDispatch();
  const setPhoneNumber = (e) => {
    const index = propToDisplay.findIndex((x) => x.id === e.target.id);
    dispatch(setCallPhone(propToDisplay[index]));
  };

  return (
    <>
      {propToDisplay && propToDisplay.length > 0 ? (
        <>
          {Array.isArray(propToDisplay) ? (
            <>
              {propToDisplay.map((prop) => {
                return (
                  <div className="col-md-4" style={{ marginTop: "2vh" }}>
                    <b>
                      {prop.type.type ? toTitleCase(prop.type.type) : prop.text}{" "}
                      {propName ? propName : ""}:
                    </b>{" "}
                    {
                      {
                        Phone: formatPhoneNumber(prop.number),
                      }[propName]
                    }{" "}
                    {phone && phone.id && phone.id !== prop.id ? (
                      <>
                        <ActionBadgeBase
                          background={"primary"}
                          text={"Use this #"}
                          id={prop.id}
                          useFunction={setPhoneNumber}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              <div className="col-md-3" style={{ marginTop: "2vh" }}>
                <b>{propName ? propName : ""}:</b> {propToDisplay}
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CallTimeStats = () => {
  const [progress, setProgress] = useState({
    now: 0,
    total: 1,
  });
  const callTime = useSelector((state) => state.calls.callTime);
  const list = useSelector((state) => state.gets.list);

  useEffect(() => {
    if (
      list &&
      list.contacts &&
      list.contacts.length > 0 &&
      callTime.calls &&
      callTime.calls.length > 0
    ) {
      const deDuped = [...new Set(callTime.calls.map((it) => it.contactId))];
      if (deDuped && deDuped.length > 0)
        setProgress({
          ...progress,
          now: deDuped.length,
          total: list.contacts.length,
        });
    }
    if (callTime && callTime.calls.length === 0) {
      setProgress({
        now: 0,
        total: 1,
      });
    }
  }, [callTime, list]);

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "5vh" }}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <h3 className="text-decoration-underline">This Call Time:</h3>
            </div>
            <CallTimeClock />
            <div className="container-fluid" style={{ marginTop: "2vh" }}>
              <ProgressBar
                animated
                variant="success"
                label={`${Math.round(
                  ((progress.now / progress.total) * 100 * 100) / 100
                )}% of Contacts Called`}
                now={(progress.now / progress.total) * 100}
              />
            </div>
            <hr />
            <div className="row"></div>
            <div className="row align-items-center">
              <div className="col-md-6">
                <CallTimeCallsTracker height={"30vh"} />
              </div>
              <div className="col-md-6">
                <CallTimePledgeStats />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CallTimePledgeStats = () => {
  const callTime = useSelector((state) => state.calls.callTime);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <h4>
            {callTime && callTime.pledges
              ? `$${callTime.pledges.reduce(
                  (sum, current) => sum + parseInt(current.amount),
                  0
                )}`
              : ""}{" "}
            pledged
          </h4>
        </div>
        <div className="row">
          <h4>
            {callTime && callTime.calls ? `${callTime.calls.length}` : ""}{" "}
            {callTime && callTime.calls && callTime.calls.length === 1
              ? "call"
              : "calls"}{" "}
            made
          </h4>
        </div>
      </div>
    </>
  );
};

const CallTimeClock = () => {
  const [timeLeft, setTimeLeft] = useState();
  const [duration, setDuration] = useState();

  const callTime = useSelector((state) => state.calls.callTime);

  const calculateTimeLeft = (end, start, property) => {
    let difference = +end - +start;
    let time = {};

    if (difference > 0) {
      time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    if (property === "duration") {
      setDuration({ ...time });
    } else {
      setTimeLeft({ ...time });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callTime) {
        calculateTimeLeft(new Date(), new Date(callTime.start), "duration");
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      if (callTime) {
        calculateTimeLeft(new Date(callTime.end), new Date(), "remaining");
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="row" style={{ marginTop: "1vh" }}>
        <div className="col-md-4">
          Started: {new Date(callTime.start).toDateString()}
        </div>
        <div className="col-md-4">
          {duration && !duration.days ? (
            <></>
          ) : (
            <>
              Duration:{" "}
              {duration &&
              !(
                duration.days === 0 &&
                duration.minutes === 0 &&
                duration.hours === 0 &&
                duration.seconds === 0
              )
                ? `${duration.hours}:${duration.minutes}:${duration.seconds}`
                : "Time's up!"}
            </>
          )}
        </div>
        <div className="col-md-4">
          {timeLeft && !timeLeft.days ? (
            <></>
          ) : (
            <>
              Remaining:{" "}
              {timeLeft &&
              !(
                timeLeft.days === 0 &&
                timeLeft.minutes === 0 &&
                timeLeft.hours === 0 &&
                timeLeft.seconds === 0
              )
                ? `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`
                : "Time's up!"}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const CallTimeCallsTracker = ({ height }) => {
  const [summary, setSummary] = useState([]);
  const callTime = useSelector((state) => state.calls.callTime);
  const outcomes = useSelector((state) => state.calls.outcomes);

  useEffect(() => {
    if (
      callTime &&
      callTime.calls &&
      callTime.calls.length > 0 &&
      outcomes &&
      outcomes.length > 0
    ) {
      constructSummaryData(callTime.calls);
    } else {
      setSummary([]);
    }
  }, [callTime, outcomes]);

  const constructSummaryData = (calls) => {
    const tempSummary = [];
    outcomes.map((outcome) => {
      tempSummary.push({ ...outcome, count: 0 });
      return;
    });
    calls.map((call) => {
      const index = tempSummary.findIndex(
        (outcome) => outcome.id === call.outcomeId
      );
      tempSummary[index].count += 1;
      return;
    });
    setSummary([...new Set([...tempSummary.filter((sum) => sum.count !== 0)])]);
  };

  return (
    <div className="container-fluid">
      <div>
        <div style={{ maxHeight: `${height}` }}>
          <div className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Response</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>
              {summary && summary.length > 0 ? (
                <>
                  {summary.map((it) => (
                    <tr key={it.id}>
                      <td>{it.value}</td>
                      <td>{it.count}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr colSpan="100%">No Calls Yet</tr>
                </>
              )}
            </tbody>
          </div>
        </div>
      </div>
    </div>
  );
};
