import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ResuableBreadcrumbs = ({ array }) => {
  const history = useHistory();

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {array && array.length > 0 ? (
          <>
            {array.map((item) => (
              <li
                className={`breadcrumb-item ${item.active ? "active" : ""}`}
                key={`breadcrumb-${item.value}`}
              >
                {item.href ? (
                  <a
                    href={"#/" + item.href}
                    onClick={(e) => history.push(`/${item.href}`)}
                  >
                    {item.value}
                  </a>
                ) : (
                  <> {item.value}</>
                )}
              </li>
            ))}
          </>
        ) : (
          <></>
        )}
      </ol>
    </nav>
  );
};
