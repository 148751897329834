import { SET_CALL_PHONE } from "../actions/types";
import {
  SET_CALL_TIMES,
  SET_CALL_TIME,
  DELETE_CALL_TIME,
  ADD_CALL_TO_CALL_TIME,
  SET_OUTCOME_TYPES,
  ADD_NEW_ENTITY_TO_CALL_TIME,
  DELETE_ENTITY_FROM_CALL_TIME,
} from "../actions/types";

const initialState = {
  phone: {
    number: "",
  },
  callTimes: [],
  callTime: false,
  outcomes: [],
};

const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const setResource = (field, value) => {
  const fieldToUpdate = field;
  if (Array.isArray(value)) {
    fieldToUpdate.push(...value);
  } else {
    fieldToUpdate.push(value);
  }
  const deduped = getUniqueListBy(fieldToUpdate, "id");
  return deduped;
};

function callTimeReducer(state = initialState, action) {
  const { callTimes, callTime, outcomes } = state;
  let fieldToUpdate, indexToPop, resource;
  switch (action.type) {
    case SET_CALL_TIMES:
      resource = setResource(callTimes, action.payload.value);
      return {
        ...state,
        callTimes: [...resource],
      };
    case SET_CALL_TIME:
      return {
        ...state,
        callTime: action.payload,
      };
    case DELETE_CALL_TIME:
      fieldToUpdate = callTimes;
      indexToPop = fieldToUpdate.findIndex((i) => i.id === action.payload);
      fieldToUpdate.splice(indexToPop, 1);
      return {
        ...state,
        callTimes: [...fieldToUpdate],
      };
    case SET_CALL_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case ADD_CALL_TO_CALL_TIME:
      fieldToUpdate = callTime.calls;
      fieldToUpdate.push(action.payload);
      fieldToUpdate = [...new Set(fieldToUpdate)];
      return {
        ...state,
        callTime: {
          ...callTime,
          calls: [...fieldToUpdate],
        },
      };
    case ADD_NEW_ENTITY_TO_CALL_TIME:
      fieldToUpdate = callTime[action.payload.field];
      indexToPop = fieldToUpdate.findIndex(
        (i) => i.id === action.payload.value.id
      );
      console.log(indexToPop);
      if (indexToPop >= 0) {
        fieldToUpdate.splice(indexToPop, 1);
      }
      fieldToUpdate.push(action.payload.value);
      return {
        ...state,
        callTime: {
          ...callTime,
          [[action.payload.field]]: [...fieldToUpdate],
        },
      };
    case DELETE_ENTITY_FROM_CALL_TIME:
      fieldToUpdate = callTime[action.payload.field];
      indexToPop = fieldToUpdate.findIndex((i) => i.id === action.payload.id);
      if (indexToPop !== -1) {
        fieldToUpdate.splice(indexToPop, 1);
      }
      return {
        ...state,
        callTime: {
          ...callTime,
          [[action.payload.field]]: [...fieldToUpdate],
        },
      };
    case SET_OUTCOME_TYPES:
      resource = setResource(outcomes, action.payload.value);
      return {
        ...state,
        outcomes: [...resource],
      };
    default:
      return state;
  }
}
export default callTimeReducer;
