import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setList, deleteListFromLists } from "../../actions/queryActions";
import { address } from "../../utils/connectionAddress";

export default function ChooseListComponent({
  lists,
  setShow,
  label,
  dynamicSet,
  list,
}) {
  const [chosen, setChosenList] = useState({ name: "", id: "" });
  const [useGlobal, setUseGlobal] = useState(false);
  const dispatch = useDispatch();

  const changeListDynamic = (e) => {
    const selected = e.target.selectedIndex;
    const optionElement = e.target.childNodes[selected];
    const optionElementId = optionElement.getAttribute("listid");
    const index = lists.findIndex((x) => x.id === optionElementId);
    if (lists[index] && lists[index].name) {
      dispatch(setList(lists[index]));
    } else {
      dispatch(setList(undefined));
    }
  };

  const changeList = (e) => {
    e.preventDefault();
    if (chosen.name === "") {
      dispatch(setList(undefined));
    } else {
      dispatch(setList(chosen));
      setChosenList({
        name: "",
        id: "",
      });
      setShow(false);
    }
  };

  const deleteList = async (e) => {
    e.preventDefault();
    if (chosen.name === "") {
      return;
    } else {
      try {
        await axios.delete(address + `/lists/${chosen.id}`);
        dispatch(deleteListFromLists(chosen.id));
        setChosenList({
          name: "",
          id: "",
        });
        dispatch(setList(undefined));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onChange = (e) => {
    const selected = e.target.selectedIndex;
    const optionElement = e.target.childNodes[selected];
    const optionElementId = optionElement.getAttribute("listid");
    console.log(optionElementId);
    const index = lists.findIndex((x) => x.id === optionElementId);
    setChosenList({
      ...lists[index],
    });
  };

  return (
    <>
      {lists && lists.length > 0 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Form.Check
                className="float-right"
                type="checkbox"
                id={`default-checkbox`}
                label={`Use a G3-Wide List?`}
                checked={useGlobal}
                onChange={() => setUseGlobal(!useGlobal)}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className={`col-md-${chosen && chosen.contacts ? "9" : "12"}`}>
              <Form.FloatingLabel label={label} className="mb-3">
                {dynamicSet ? (
                  <>
                    <Form.Control
                      as="select"
                      value={list && list.name ? list.name : ""}
                      name="chosen"
                      onChange={(e) => changeListDynamic(e)}
                    >
                      <option>{""}</option>
                      {lists
                        ? lists
                            .filter((list) => {
                              return list.global === useGlobal;
                            })
                            .map((list) => {
                              return (
                                <option listid={list.id}>{list.name}</option>
                              );
                            })
                        : ""}
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Control
                      as="select"
                      value={chosen.name}
                      name="chosen"
                      onChange={(e) => onChange(e)}
                    >
                      <option>{""}</option>
                      {lists
                        ? lists
                            .filter((list) => {
                              return list.global === useGlobal;
                            })
                            .map((list) => {
                              return (
                                <option listid={list.id}>{list.name}</option>
                              );
                            })
                        : ""}
                    </Form.Control>
                  </>
                )}
              </Form.FloatingLabel>
            </div>
            {chosen && chosen.contacts ? (
              <div className="col-md-3">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deleteList(e)}
                >
                  Delete List
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="row">
            <div className="col-md-5">
              <p>
                {chosen && chosen.contacts ? (
                  <>This list has {chosen.contacts.length} contacts</>
                ) : (
                  <></>
                )}
              </p>
            </div>
            <div className="col-md-4"></div>
          </div>
          {dynamicSet ? (
            <></>
          ) : (
            <div className="row">
              <div className="col-md-5">
                <div className="btn btn-primary" onClick={changeList}>
                  Choose list
                </div>
              </div>
              <div className="col-md-4" />
              <div className="col-md-3">
                <div
                  className="btn btn-outline-secondary"
                  onClick={() => setShow(false)}
                >
                  Back
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <p>
            You don't currently have any lists, please create one to continue.
          </p>
        </>
      )}
    </>
  );
}
