export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CONTACT_PROPERTY_BASIC = "SET_CONTACT_PROPERTY_BASIC";
export const SET_CONTACT_PROPERTY_RELATION = "SET_CONTACT_PROPERTY_RELATION";
export const SET_CONTACT = "SET_CONTACT";
export const CLEAR_RESOURCE = "CLEAR_RESOURCE";
export const ADD_NEW_ENTITY_TO_CONTACT = "ADD_NEW_ENTITY_TO_CONTACT";
export const DELETE_ENTITY_FROM_CONTACT = "DELETE_ENTITY_FROM_CONTACT";
export const SET_LIST = "SET_LIST";
export const DELETE_LIST = "DELETE_LIST";
export const SET_LISTS = "SET_LISTS";
export const ADD_CONTACT_TO_LIST = "ADD_CONTACT_TO_LIST";
export const REMOVE_CONTACT_FROM_LIST = "REMOVE_CONTACT_FROM_LIST";
export const SET_CALL_TIMES = "SET_CALL_TIMES";
export const SET_CALL_TIME = "SET_CALL_TIME";
export const DELETE_CALL_TIME = "DELETE_CALL_TIME";
export const SET_CANDIDATES = "SET_CANDIDATES";
export const DELETE_CANDIDATE = "DELETE_CANDIDATE";
export const SET_CALL_PHONE = "SET_CALL_PHONE";
export const ADD_CALL_TO_CALL_TIME = "ADD_CALL_TO_CALL_TIME";
export const ADD_NEW_ENTITY_TO_CALL_TIME = "ADD_NEW_ENTITY_TO_CALL_TIME";
export const DELETE_ENTITY_FROM_CALL_TIME = "DELETE_ENTITY_FROM_CALL_TIME";
export const SET_OUTCOME_TYPES = "SET_OUTCOME_TYPES";
