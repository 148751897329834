import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from "react";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ExportToCsv } from "export-to-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { address } from "../../utils/connectionAddress";
import { removeContactFromList } from "../../actions/queryActions";

function ListDataCheck() {
  const [show, setShow] = useState(false);
  const [contacts, setContacts] = useState(undefined);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //const [loading, isLoading] = useState(null);
  const dispatch = useDispatch();
  const currentList = useSelector((state) => state.gets.list);

  useEffect(() => {
    if (
      currentList &&
      currentList.contacts &&
      currentList.contacts.length > 0
    ) {
      getContactsFromList();
    } else {
      setContacts(undefined);
    }
  }, [currentList]);

  const getContactsFromList = async () => {
    try {
      const contacts = await axios.get(
        `${address}/contacts?${currentList.contacts
          .map((n, index) => `id[${index}]=${n}`)
          .join("&")}&includes[0]=phones&includes[1]s=emails`
      );
      setContacts(contacts.data);
    } catch (err) {
      console.log(err);
    }
  };
  const downloadCsv = (e, data) => {
    e.preventDefault();
    let downloadData = data.map(d => {
      d.phones = d.phones.map(p => p.number).join(';')
      d.emails = d.emails.map(p => p.email).join(';')
      return d
    })
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: currentList.name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(downloadData);
  };

  const removeFromList = async (e, id) => {
    e.preventDefault();
    try {
      await axios.delete(`${address}/lists/${currentList.id}/contacts/${id}`);
      dispatch(removeContactFromList(id));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Button
        variant="primary"
        className="float-right"
        onClick={handleShow}
        block
      >
        View Current List Contacts
      </Button>

      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {contacts ? (
            <>
              <Button
                onClick={(e) => downloadCsv(e, contacts)}
                className="float-right"
                variant="link"
              >
                Download List
              </Button>
            </>
          ) : (
            ""
          )}
          {contacts ? (
            <Table size="sm">
              <thead>
                <tr>
                  <th>Contact</th>
                  <th>Phone?</th>
                  <th>Email?</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <>
                  {contacts.map((contributor, idx) => (
                    <tr className="text-left" key={idx}>
                      <td data-item={idx}>{`${contributor.name}`}</td>
                      <>
                        {contributor.phones && contributor.phones.length > 0 ? (
                          <td data-item={idx}>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="green"
                              size="sm"
                            />
                          </td>
                        ) : (
                          <td data-item={idx}>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              color="red"
                              size="sm"
                            />
                          </td>
                        )}
                      </>
                      <>
                        {!contributor.emails &&
                        !contributor.emails.length > 0 ? (
                          <td data-item={idx}>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              color="red"
                              size="sm"
                            />
                          </td>
                        ) : (
                          <td data-item={idx}>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="green"
                              size="sm"
                            />
                          </td>
                        )}
                      </>
                      <td data-item={idx}>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 200 }}
                          overlay={
                            <Tooltip id="button-tooltip">
                              {"Remove from list"}
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={(e) => removeFromList(e, contributor.id)}
                            style={{
                              padding: "0.05rem 0.7rem",
                              fontSize: "12px",
                            }}
                            size="sm"
                            variant="danger"
                          >
                            <FontAwesomeIcon
                              style={{ marginTop: ".2rem" }}
                              icon={faMinus}
                              color="white"
                              size="sm"
                            />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </Table>
          ) : (
            <Modal.Title>
              You need to add contacts to your list before they will show up
              here.
            </Modal.Title>
          )}
          <Row>
            <Col>
              <Button
                variant="outline-secondary"
                onClick={handleClose}
                className="float-right"
              >
                Back
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ListDataCheck;
