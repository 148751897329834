import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faMinus,
  faSave,
  faUndoAlt,
  faCopy,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import { useCopyToClipboard } from "react-use";
import { useState } from "react";

export const EditBadge = ({ editFunction }) => {
  return (
    <div className="btn" style={{ padding: "0" }}>
      <div className="badge rounded-pill bg-warning" onClick={editFunction}>
        <FontAwesomeIcon size="sm" icon={faEdit} />
      </div>
    </div>
  );
};

export const DeleteBadge = ({ deleteFunction }) => {
  return (
    <div className="btn" style={{ padding: "0" }}>
      <div className="badge rounded-pill bg-danger" onClick={deleteFunction}>
        <FontAwesomeIcon size="sm" icon={faMinus} />
      </div>
    </div>
  );
};

export const SaveBadge = ({ saveFunction }) => {
  return (
    <div className="btn" style={{ padding: "0" }}>
      <div className="badge rounded-pill bg-success" onClick={saveFunction}>
        Save <FontAwesomeIcon size="sm" icon={faSave} />
      </div>
    </div>
  );
};

export const UndoBadge = ({ undoFunction }) => {
  return (
    <div className="btn" style={{ padding: "0" }}>
      <div className="badge rounded-pill bg-secondary" onClick={undoFunction}>
        <FontAwesomeIcon size="sm" icon={faUndoAlt} />
      </div>
    </div>
  );
};

export const CopyToClipboardBadge = ({ textToCopy }) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [notTimedOut, setTimedOut] = useState(null);

  return (
    <div className="btn" style={{ padding: "0" }}>
      <div
        className="badge rounded-pill bg-dark"
        onClick={() => {
          copyToClipboard(textToCopy);
          setTimeout(() => {
            setTimedOut(true);
          }, 8000);
        }}
      >
        {state.value && !notTimedOut ? (
          <FontAwesomeIcon size="sm" icon={faCheck} />
        ) : (
          <FontAwesomeIcon size="sm" icon={faCopy} />
        )}
      </div>
    </div>
  );
};

export const ActionBadgeBase = ({ useFunction, text, background, id }) => {
  return (
    <div className="btn" style={{ padding: "0" }}>
      <Badge pill bg={background} id={id} onClick={useFunction}>
        {text}
      </Badge>
    </div>
  );
};
