import { SET_CANDIDATES, DELETE_CANDIDATE } from "./types";

export const setCandidates = (obj) => {
  return {
    type: SET_CANDIDATES,
    payload: obj,
  };
};

export const removeCandidate = (obj) => {
  return {
    type: DELETE_CANDIDATE,
    payload: obj,
  };
};
