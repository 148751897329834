import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";
import { useSelector, useDispatch } from "react-redux";
import { deleteEntityFromContact } from "../../actions/queryActions";
import { DeleteBadge } from "./ActionBadges";

function DeleteEntityModal({ field, id }) {
  const [show, setShow] = useState(false);

  const contact = useSelector((state) => state.gets.contact);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = async (e) => {
    setShow(true);
  };

  const deleteFromContact = async (e) => {
    e.preventDefault();
    let route;
    if (field === "Address") {
      route = "addresses";
    } else {
      route = `${field.toLowerCase()}s`;
    }
    if (contact && id) {
      try {
        await axios.delete(`${address}/${route}/${id}`);
        dispatch(
          deleteEntityFromContact({
            field: `${route}`,
            id: id,
          })
        );
        handleClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      handleClose();
    }
  };

  return (
    <>
      <DeleteBadge deleteFunction={handleShow} />

      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">
            Delete {field.toLowerCase()} from {contact.name}'s contact?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row align-items-center">
            <div className="col ">
              <div
                className="btn btn-danger ml-auto p-2"
                onClick={(e) => deleteFromContact(e)}
              >
                Yes, delete this {field}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteEntityModal;
