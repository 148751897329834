import { combineReducers } from "redux";
import getReducer from "./queryReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import callsReducer from "./callTimeReducer";

export default combineReducers({
  calls: callsReducer,
  gets: getReducer,
  auth: authReducer,
  errors: errorReducer,
});
