import { useState } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Modal } from "react-bootstrap";
import { address } from "../../../utils/connectionAddress";
import axios from "axios";
import { setContact } from "../../../actions/queryActions";
import { useDispatch } from "react-redux";

const displayTypes = [
  {
    field: "firstName",
    text: "First Name",
  },
  {
    field: "lastName",
    text: "Last Name",
  },
  {
    field: "name",
    text: "Name",
  },
  {
    field: "employer",
    text: "Employer",
  },
  {
    field: "occupation",
    text: "Occupation",
  },
  {
    field: "salutation",
    text: "Salutation",
  },
  {
    field: "contactPerson",
    text: "Contact Person",
  },
];

const CreateContact = () => {
  const [show, setShow] = useState(false);
  const [newContact, setNewContact] = useState({});
  const [contactType, setContactType] = useState(true);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();

  const createContact = async (e) => {
    e.preventDefault();
    const data = {
      ...newContact,
      name: newContact?.contactPerson
        ? newContact.name
        : `${newContact.firstName} ${newContact.lastName}`,
      type: contactType ? "Individual" : "Organization",
    };
    try {
      const createdContact = await axios.post(address + "/contacts", {
        contact_data: data,
      });
      const foundContact = await axios.get(
        address + "/contacts/" + createdContact.data.id
      );
      dispatch(setContact(foundContact.data));
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = ({ value, field }) => {
    if (field && (value || value === "")) {
      setNewContact({ ...newContact, [[field]]: value });
    }
  };

  return (
    <div>
      <button
        className="btn btn-outline-light"
        style={{ marginRight: "1vw" }}
        onClick={handleShow}
      >
        New Contributor
      </button>

      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="xl"
      >
        <div className="modal-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9 col-sm-5">
                <h4 className="modal-title">Add New Contributor</h4>
              </div>
              <div className="col-md-3 col-sm-5">
                <BootstrapSwitchButton
                  checked={contactType}
                  onlabel="Individual"
                  onstyle="primary"
                  offlabel="Organization"
                  offstyle="dark"
                  style="w-100 p-3"
                  onChange={() => {
                    setContactType(!contactType);
                    setNewContact({});
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              {displayTypes.map((display) => {
                if (
                  contactType &&
                  (display.field === "contactPerson" ||
                    display.field === "name")
                ) {
                  return;
                } else if (
                  !contactType &&
                  (display.field === "firstName" ||
                    display.field === "lastName" ||
                    display.field === "occupation" ||
                    display.field === "employer")
                ) {
                } else {
                  return (
                    <DataEditor
                      contact={newContact}
                      display={display}
                      onChange={onChange}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={(e) => createContact(e)}>
            Add Contributor
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={handleClose}
          >
            Back
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateContact;

const DataEditor = ({ contact, display, onChange, textBox }) => {
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="mb-3 row align-items-center">
          <label className="col-sm-4">
            <b>{display.text}:</b>{" "}
          </label>
          {textBox ? (
            <>
              <div className="col-sm-8">
                <textarea
                  className="form-control"
                  rows="3"
                  value={contact[display.field]}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-sm-8">
                <input
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={contact[display.field]}
                  onChange={(e) =>
                    onChange({ field: display.field, value: e.target.value })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
