import {
  SET_CONTACT_PROPERTY_BASIC,
  SET_CONTACT_PROPERTY_RELATION,
  ADD_NEW_ENTITY_TO_CONTACT,
  DELETE_ENTITY_FROM_CONTACT,
  SET_CONTACT,
  SET_LIST,
  DELETE_LIST,
  SET_LISTS,
  REMOVE_CONTACT_FROM_LIST,
  ADD_CONTACT_TO_LIST,
  CLEAR_RESOURCE,
} from "./types";

export const editContact = (obj) => {
  if (obj.relationship) {
    return {
      type: SET_CONTACT_PROPERTY_RELATION,
      payload: obj,
    };
  } else {
    return {
      type: SET_CONTACT_PROPERTY_BASIC,
      payload: obj,
    };
  }
};

export const setContact = (obj) => {
  return {
    type: SET_CONTACT,
    payload: obj,
  };
};

export const clearContact = () => {
  return {
    type: CLEAR_RESOURCE,
    payload: {
      field: "contact",
    },
  };
};

export const clearList = () => {
  return {
    type: CLEAR_RESOURCE,
    payload: {
      field: "list",
    },
  };
};

export const addNewEntityToContact = (obj) => {
  return {
    type: ADD_NEW_ENTITY_TO_CONTACT,
    payload: obj,
  };
};

export const deleteEntityFromContact = (obj) => {
  return {
    type: DELETE_ENTITY_FROM_CONTACT,
    payload: obj,
  };
};

export const setLists = (obj) => {
  return {
    type: SET_LISTS,
    payload: obj,
  };
};

export const setList = (obj) => {
  return {
    type: SET_LIST,
    payload: obj,
  };
};

export const deleteListFromLists = (id) => {
  return {
    type: DELETE_LIST,
    payload: id,
  };
};

export const addContactToList = (obj) => {
  return {
    type: ADD_CONTACT_TO_LIST,
    payload: obj,
  };
};

export const removeContactFromList = (obj) => {
  return {
    type: REMOVE_CONTACT_FROM_LIST,
    payload: obj,
  };
};
