import { Pagination } from "react-bootstrap";
import React, { useState, useEffect } from "react";

const FoundContacts = ({ contributors, showContributor }) => {
  const [active_page, setActivePage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [notFound, setNotFound] = useState(false);
  const [paginatedContributors, setPaginated] = useState([]);

  useEffect(() => {
    setActivePage(1);
    if (contributors && contributors.length > 0) {
      setNotFound(false);
      const copyContribs = [...contributors];
      const arrays = [],
        size = 5;
      while (copyContribs.length > 0) arrays.push(copyContribs.splice(0, size));
      setPaginated(arrays);
    } else if (contributors && contributors.length === 0) {
      setNotFound(true);
    }
  }, [contributors]);

  return (
    <>
      {notFound ? (
        <table
          className="table table-bordered"
          style={{ marginTop: 5, height: "30vh" }}
        >
          <thead>
            <tr>
              <th>Contact Name</th>
              <th>Contact Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="text-center"
                colSpan="100%"
                style={{ height: "25vh" }}
              >
                No Contacts Found
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <>
          {paginatedContributors && paginatedContributors.length > 0 ? (
            <div className="row">
              <div className="col">
                <h5>
                  Showing{" "}
                  {(active_page - 1) * 5 +
                    paginatedContributors[active_page - 1].length}{" "}
                  of {contributors.length}
                </h5>
                <table
                  className="table table-bordered"
                  style={{ marginTop: 5 }}
                >
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Contact Type</th>
                    </tr>
                  </thead>
                  <tbody className="tableHover">
                    {paginatedContributors[active_page - 1].map(
                      (contributor, idx) => (
                        <tr
                          key={contributor.id}
                          onClick={(e) => showContributor(e)}
                          className={
                            parseInt(selectedRow) === parseInt(idx)
                              ? "tableSelected"
                              : ""
                          }
                        >
                          <td id={contributor.id}>{`${contributor.name}`}</td>
                          <td id={contributor.id}>{contributor.type}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                  <tfoot>
                    <div className="row">
                      <div className="col-md-4" />
                      <div className="col-md-8">
                        {contributors && contributors.length > 0 ? (
                          <PaginationParent
                            items={contributors}
                            pageSize={5}
                            setSelectedRow={setSelectedRow}
                            setActivePage={setActivePage}
                            activePage={active_page}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </tfoot>
                </table>
              </div>
            </div>
          ) : (
            <table
              className="table table-bordered"
              style={{ marginTop: 5, height: "30vh" }}
            >
              <thead>
                <tr>
                  <th>Contact Name</th>
                  <th>Contact Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="100%" style={{ height: "25vh" }}></td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default FoundContacts;

const PaginationParent = ({
  items,
  pageSize,
  setSelectedRow,
  activePage,
  setActivePage,
}) => {
  const [pages, setPages] = useState();
  useEffect(() => {
    console.log(activePage);
  }, [activePage]);

  const pageBack = (e) => {
    e.preventDefault();
    const previousPage = parseInt(activePage) - 1;
    setSelectedRow(-1);
    setActivePage(previousPage);
  };

  const pageForward = (e) => {
    e.preventDefault();
    const nextPage = parseInt(activePage) + 1;
    setSelectedRow(-1);
    setActivePage(nextPage);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      console.log(Math.ceil(items.length / pageSize));
      console.log(items.length / pageSize);
      const pagesNeeded = Math.ceil(items.length / pageSize);
      setPages(pagesNeeded);
    }
  }, [items, pageSize]);

  return (
    <Pagination>
      {activePage === 1 ? (
        <Pagination.Prev disabled />
      ) : (
        <Pagination.Prev onClick={pageBack} />
      )}
      <PaginationFactory
        totalPages={pages}
        activePage={activePage}
        setActivePage={setActivePage}
        setSelectedRow={setSelectedRow}
      />
      {activePage === parseInt(pages) ? (
        <Pagination.Next disabled />
      ) : (
        <Pagination.Next onClick={pageForward} />
      )}
    </Pagination>
  );
};

const PaginationFactory = ({
  totalPages,
  activePage,
  setActivePage,
  setSelectedRow,
}) => {
  const setPage = (e) => {
    e.preventDefault();
    setSelectedRow(-1);
    setActivePage(parseInt(e.target.getAttribute("dataId")));
  };

  return (
    <>
      {totalPages && totalPages === 1 ? (
        <>
          <Pagination.Item key={1} active={true}>
            1
          </Pagination.Item>
        </>
      ) : (
        <>
          {parseInt(activePage) - 2 > 0 ? (
            <>
              {[...Array(2)]
                .map((it, i) => {
                  return (
                    <Pagination.Item
                      onClick={setPage}
                      key={parseInt(activePage) - parseInt(i) - 1}
                      dataId={parseInt(activePage) - parseInt(i) - 1}
                    >
                      {parseInt(activePage) - parseInt(i) - 1}
                    </Pagination.Item>
                  );
                })
                .reverse()}
            </>
          ) : (
            <>
              {parseInt(activePage) - 1 > 0 ? (
                <>
                  {[...Array(1)].map((it, i) => {
                    return (
                      <Pagination.Item
                        onClick={setPage}
                        key={parseInt(activePage) - parseInt(i) - 1}
                        dataId={parseInt(activePage) - parseInt(i) - 1}
                      >
                        {parseInt(activePage) - parseInt(i) - 1}
                      </Pagination.Item>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <Pagination.Item key={activePage} active={true}>
            {parseInt(activePage)}
          </Pagination.Item>
          {parseInt(activePage) + 2 > parseInt(totalPages) ? (
            <>
              {parseInt(activePage) + 1 > parseInt(totalPages) ? (
                <></>
              ) : (
                <>
                  {[...Array(1)].map((it, i) => {
                    return (
                      <Pagination.Item
                        onClick={setPage}
                        key={parseInt(activePage) + parseInt(i) + 1}
                        dataId={parseInt(activePage) + parseInt(i) + 1}
                      >
                        {parseInt(activePage) + parseInt(i) + 1}
                      </Pagination.Item>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <>
              {[...Array(2)].map((it, i) => {
                return (
                  <Pagination.Item
                    onClick={setPage}
                    key={parseInt(activePage) + parseInt(i) + 1}
                    dataId={parseInt(activePage) + parseInt(i) + 1}
                  >
                    {parseInt(activePage) + parseInt(i) + 1}
                  </Pagination.Item>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
};
