import React, { useState } from "react";
import { Card, Accordion, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  CopyToClipboardBadge,
  EditBadge,
  SaveBadge,
  UndoBadge,
} from "../../BuildingBlocks/ActionBadges";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import DeleteContact from "../DeleteContact";
import DonationTable from "../../BuildingBlocks/DonationTable";
import {
  editContact,
  addContactToList,
  removeContactFromList,
} from "../../../actions/queryActions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { address } from "../../../utils/connectionAddress";
import AddButtonModal from "../../BuildingBlocks/AddContactEntityModal";
import { toTitleCase } from "../../../utils/toTitleCase";
import DeleteEntityModal from "../../BuildingBlocks/DeleteEntityModal";
import CallsTable from "../../BuildingBlocks/CallsTable";

const displayTypes = [
  {
    field: "firstName",
    text: "First Name",
  },
  {
    field: "lastName",
    text: "Last Name",
  },
  {
    field: "employer",
    text: "Employer",
  },
  {
    field: "occupation",
    text: "Occupation",
  },
  {
    field: "salutation",
    text: "Salutation",
  },
  {
    field: "type",
    text: "Contact Type",
  },
  {
    field: "contactPerson",
    text: "Contact Person",
  },
];

export default function ContributorCard() {
  const contact = useSelector((state) => state.gets.contact);
  const list = useSelector((state) => state.gets.list);
  const listContacts = useSelector((state) => {
    if (state.gets.list) {
      return state.gets.list.contacts;
    } else {
      return undefined;
    }
  });
  const dispatch = useDispatch();
  const { phones, emails, addresses } = contact;

  const removeFromList = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`${address}/lists/${list.id}/contacts/${contact.id}`);
      dispatch(removeContactFromList(contact.id));
    } catch (err) {
      console.log(err);
    }
  };

  const addToList = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${address}/lists/${list.id}/contacts/${contact.id}`);
    } catch (err) {
      console.log(err);
    }
    dispatch(addContactToList(contact.id));
  };

  return (
    <div className="card">
      {contact ? (
        <>
          <Card.Header>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10" md={6}>
                  <h3 className="text-left">
                    <span className="align-middle">{contact.name} </span>
                    {list && list.id ? (
                      <>
                        {listContacts && listContacts.includes(contact.id) ? (
                          <div
                            onClick={(e) => removeFromList(e)}
                            className="btn btn-outline-danger align-middle"
                          >
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              color="red"
                              size="md"
                            />{" "}
                            Remove from List
                          </div>
                        ) : (
                          <div
                            onClick={(e) => addToList(e)}
                            className="btn btn-outline-success align-middle"
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              color="green"
                              size="md"
                            />{" "}
                            Add to List
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </h3>
                </div>
                <div className="col-md-2">
                  <DeleteContact />
                </div>
              </div>
            </div>
          </Card.Header>
        </>
      ) : (
        <></>
      )}
      <Card.Body className="text-left">
        {contact ? (
          <div className="container fluid">
            <Accordion defaultActiveKey={["BasicInfo"]} flush alwaysOpen>
              <Accordion.Item eventKey="BasicInfo">
                <Accordion.Header>Basic Information</Accordion.Header>
                <Accordion.Body>
                  <ul class="list-group-flush">
                    {displayTypes.map((display) => (
                      <>
                        {contact[display.field] ||
                        contact[display.field] === "" ? (
                          <BasicInfoDisplay
                            display={display}
                            contact={contact}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              {[
                {
                  propToDisplay: phones,
                  propName: "Phone",
                  title: "Phone Numbers",
                },
                { propToDisplay: emails, propName: "Email", title: "Emails" },
                {
                  propToDisplay: addresses,
                  propName: "Address",
                  title: "Addresses",
                },
              ].map((item) => {
                return (
                  <AccordionSection
                    propToDisplay={item.propToDisplay}
                    propName={item.propName}
                    title={item.title}
                  />
                );
              })}
              <Accordion.Item eventKey="history">
                <Accordion.Header>Donation History</Accordion.Header>
                <Accordion.Body>
                  <DonationTable height="50vh" />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="calls">
                <Accordion.Header>Call History</Accordion.Header>
                <Accordion.Body>
                  <CallsTable height="50vh" />
                </Accordion.Body>
              </Accordion.Item>
              {
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Other Info</Accordion.Header>
                  <Accordion.Body>
                    <ul class="list-group-flush">
                      <BasicInfoDisplay
                        display={{
                          text: "Notes",
                          field: "notes",
                        }}
                        textBox={true}
                        contact={contact}
                      />
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              }
            </Accordion>
          </div>
        ) : (
          ""
        )}
      </Card.Body>
    </div>
  );
}

const AccordionSection = ({ propToDisplay, propName, title }) => {
  return (
    <>
      {propToDisplay && propToDisplay.length > 0 ? (
        <Accordion.Item eventKey={propName + propToDisplay.id}>
          <Accordion.Header>{title}</Accordion.Header>
          <Accordion.Body>
            <div className="container fluid">
              <div className="row align-items-center">
                <div className="col-md-2 col-sm-3">
                  <AddButtonModal field={propName} smallBtn={true} />
                </div>
                <div className="col-md-10 col-sm-9">
                  <ul className="list-group-flush">
                    {propToDisplay.map((prop) => {
                      return (
                        <AttributeDisplay prop={prop} propName={propName} />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ) : (
        <>
          <Accordion.Item eventKey={propName}>
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>
              <div className="container fluid">
                <div className="row align-items-center">
                  <div className="col-md-2 col-sm-3">
                    <AddButtonModal field={propName} smallBtn={true} />
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </>
      )}
    </>
  );
};

const BasicInfoDisplay = ({ display, textBox }) => {
  const [editing, setEditing] = useState(false);
  const contact = useSelector((state) => state.gets.contact);
  const dispatch = useDispatch();

  const onSave = async (e) => {
    e.preventDefault();
    // Add Async call here
    if (display !== contact) {
      try {
        await axios.put(`${address}/contacts/${contact.id}`, {
          contact_data: contact,
        });
      } catch (err) {
        console.log(err);
      }
    }
    setEditing(!editing);
  };

  const onBack = async (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const onChange = (e) => {
    dispatch(
      editContact({
        field: display.field,
        value: e.target.value,
      })
    );
  };

  return (
    <>
      <li className="list-group-item">
        <div className="row">
          <div className="col-md-8">
            <div className="mb-1 row align-items-center">
              <label className="col-md-3 col-sm-12">
                <b>{display.text}:</b>{" "}
              </label>
              {editing ? (
                <>
                  {textBox ? (
                    <>
                      <div className="col-sm-12 col-md-9">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={contact[display.field]}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-sm-9">
                        <Form.Control
                          size="sm"
                          value={contact[display.field]}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>{contact[display.field]}</>
              )}
            </div>
          </div>
          <div className="col-md-4">
            {" "}
            {editing ? (
              <>
                <SaveBadge saveFunction={(e) => onSave(e)} />{" "}
                <UndoBadge undoFunction={(e) => onBack(e)} />
              </>
            ) : (
              <>
                {" "}
                <EditBadge editFunction={() => setEditing(!editing)} />{" "}
                <CopyToClipboardBadge textToCopy={contact[display.field]} />
              </>
            )}
          </div>
        </div>
      </li>
    </>
  );
};

const AttributeDisplay = ({ prop, propName }) => {
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();

  const onSave = async (e) => {
    e.preventDefault();
    let route;
    if (propName === "Address") {
      route = "addresses";
    } else {
      route = `${propName.toLowerCase()}s`;
    }
    try {
      await axios.put(`${address}/${route}/${prop.id}`, {
        [[`${propName.toLowerCase()}_data`]]: prop,
      });
      setEditing(!editing);
    } catch (err) {
      console.log(err);
    }
  };

  const onBack = async (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const onChange = (e, name) => {
    dispatch(
      editContact({
        field: name,
        value: e.target.value,
        id: e.target.id,
        subfield: e.target.name,
        relationship: true,
      })
    );
  };

  return (
    <li class="list-group-item">
      <div className="row">
        <div className="col-md-8">
          <div className="mb-1 row align-items-center">
            <label for={propName} className="col-sm-4">
              <b>
                {prop.type.type ? toTitleCase(prop.type.type) : prop.text}{" "}
                {propName ? propName : ""}:
              </b>{" "}
            </label>
            {editing ? (
              <div className="col-sm-8">
                {
                  {
                    Phone: (
                      <Form.Control
                        size="sm"
                        value={prop.number}
                        name={"number"}
                        id={prop.id}
                        onChange={(e) => onChange(e, "phones")}
                      />
                    ),
                    Email: (
                      <Form.Control
                        size="sm"
                        value={prop.email}
                        name={"email"}
                        id={prop.id}
                        onChange={(e) => onChange(e, "emails")}
                      />
                    ),
                    Address: (
                      <>
                        <Form.Control
                          size="sm"
                          value={prop.address}
                          name={"address"}
                          id={prop.id}
                          onChange={(e) => onChange(e, "addresses")}
                        />
                        <Form.Control
                          size="sm"
                          value={prop.city}
                          name={"city"}
                          id={prop.id}
                          onChange={(e) => onChange(e, "addresses")}
                        />
                        <Form.Control
                          size="sm"
                          value={prop.state}
                          name={"state"}
                          id={prop.id}
                          onChange={(e) => onChange(e, "addresses")}
                        />
                        <Form.Control
                          size="sm"
                          value={prop.zip}
                          name={"zip"}
                          id={prop.id}
                          onChange={(e) => onChange(e, "addresses")}
                        />
                      </>
                    ),
                  }[propName]
                }
              </div>
            ) : (
              <div className="col-sm-8">
                {
                  {
                    Phone: formatPhoneNumber(prop.number),
                    Email: <a href={`mailto:${prop.email}`}>{prop.email}</a>,
                    Address: `${prop.address} ${prop.city}, ${prop.state} ${prop.zip}`,
                  }[propName]
                }
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4">
          {editing ? (
            <>
              <SaveBadge saveFunction={(e) => onSave(e)} />{" "}
              <UndoBadge undoFunction={(e) => onBack(e)} />
            </>
          ) : (
            <>
              {" "}
              <EditBadge editFunction={() => setEditing(!editing)} />{" "}
              <DeleteEntityModal id={prop.id} field={propName} />{" "}
              {
                {
                  Phone: <CopyToClipboardBadge textToCopy={prop.number} />,
                  Email: <CopyToClipboardBadge textToCopy={prop.email} />,
                  Address: (
                    <CopyToClipboardBadge
                      textToCopy={`${prop.address} ${prop.city}, ${prop.state} ${prop.zip}`}
                    />
                  ),
                }[propName]
              }
            </>
          )}
        </div>
      </div>
    </li>
  );
};
