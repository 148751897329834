import React, { useEffect, useState } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";
import { useSelector } from "react-redux";
import AddButtonModal from "./AddContactEntityModal";
import DeleteEntityModal from "./DeleteEntityModal";

const formatDate = (date) => {
  const formated = new Date(date);
  return formated.toDateString();
};

const donationTypes = [
  { id: "Donations1", type: "Online" },
  { id: "Donations2", type: "Check" },
  { id: "Donations3", type: "Cash" },
  { id: "Donations4", type: "In-Kind" },
];

export default function DonationTable({ height }) {
  const [donations, setDonations] = useState();
  const contact = useSelector((state) => state.gets.contact);

  useEffect(() => {
    if (contact) {
      getDonations();
    }
  }, [contact]);

  const getDonations = async () => {
    try {
      const donationsFound = await axios.get(
        `${address}/donations?contact_id=${contact.id}`
      );
      setDonations(donationsFound.data);
    } catch (err) {
      console.log(err);
      setDonations();
    }
  };

  const averageDonation = (donations) => {
    let total = 0;
    donations.map((donation) => {
      total += donation.amount;
    });
    const avg = total / donations.length;
    return avg;
  };

  const totalDonations = (donations) => {
    let total = 0;
    donations.map((donation) => {
      total += donation.amount;
    });
    return total;
  };

  return (
    <>
      {donations && donations.length > 0 ? (
        <>
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="card-text">
                <b>Total Donations:</b> ${totalDonations(donations).toFixed(2)}
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-text">
                <b>Number of Donations:</b> {donations.length}
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-text">
                <b>Average Donation:</b> $
                {averageDonation(donations).toFixed(2)}{" "}
              </div>
            </div>
            <div className="col-md-3">
              <AddButtonModal
                field={"Donation"}
                smallBtn={true}
                staticTypes={donationTypes}
              />{" "}
            </div>
          </div>
          <br />
          <div
            className="table-responsive"
            style={{ overflow: "auto", maxHeight: `${height}` }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col">Candidate</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Donation</th>
                  <th scope="col">Date</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation) => (
                  <tr>
                    <td>{donation.candidate.name}</td>
                    <td>{donation.campaign.type}</td>
                    <td>${+donation.amount.toFixed(2)}</td>
                    <td>{formatDate(donation.date)}</td>
                    <td>{donation.type}</td>
                    <td id={donation.id}>
                      <DeleteEntityModal id={donation.id} field={"Donation"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="card-text">
                <b>Total Donations:</b> $ --
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-text">
                <b>Average Donation:</b> $ --
              </div>
            </div>
            <div className="col-md-3">
              <AddButtonModal
                field={"Donation"}
                smallBtn={true}
                staticTypes={donationTypes}
              />{" "}
            </div>
          </div>
          <div
            className="table-responsive"
            style={{ overflow: "auto", maxHeight: `${height}` }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col">Candidate</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Donation</th>
                  <th scope="col">Date</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="100%" className="text-center">
                    No Donations Found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
