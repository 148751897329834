import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";
import { useDispatch, useSelector } from "react-redux";
import { clearContact } from "../../actions/queryActions";

function DeleteContact() {
  const [show, setShow] = useState(false);

  const contact = useSelector((state) => state.gets.contact);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteThisContact = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(`${address}/contacts/${contact.id}`);
      dispatch(clearContact());
      setShow(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={handleShow}>
        Delete Contact
      </button>

      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Are you sure you want to delete {contact.name}'s contact and all its
            associated data?
          </h5>
          <button
            type="button"
            className="btn btn-close"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={(e) => deleteThisContact(e)}
          >
            Confirm Deletion
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DeleteContact;
