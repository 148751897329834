import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { removeCandidate, setCandidates } from "../../actions/candidateActions";
import { address } from "../../utils/connectionAddress";

const campaignTypes = [
  { locale: "Citywide", types: ["Council", "Mayor", "School Board"] },
  { locale: "Statehouse", types: ["Representative", "Senate"] },
  {
    locale: "Countywide",
    types: [
      "Judicial",
      "Commissioner",
      "Clerk of Courts",
      "Recorder",
      "Auditor",
    ],
  },
  {
    locale: "Statewide",
    types: ["Treasurer", "Governor", "Secretary of State"],
  },
];

export const AddCandidateModal = ({ btnVariant, smallBtn }) => {
  const [candidate, setCandidate] = useState({});
  const [campaign, setCampaign] = useState({});
  const [invalid, setInvalid] = useState(false);
  const [added, setAdded] = useState(false);
  const [show, setShow] = useState(false);

  const candidates = useSelector((state) => state.gets.candidates);
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidate && candidate.name && candidates) {
      const dupeCandidateIndex = candidates.findIndex(
        (can) => can.name.toUpperCase() === candidate.name.toUpperCase()
      );
      dupeCandidateIndex !== -1 ? setInvalid(true) : setInvalid(false);
      return;
    }
  }, [candidate]);

  const onChangeCandidate = (item) => {
    setCandidate({ ...candidate, [[item.field]]: item.value });
  };
  const onChangeCampaign = (item) => {
    setCampaign({ ...campaign, [[item.field]]: item.value });
  };

  const handleClose = () => {
    setCandidate({ ...candidate, name: "" });
    setCandidate({});
    setCampaign({});
    setInvalid(false);
    setAdded(false);
    setShow(false);
  };
  const handleShow = async (e) => {
    setShow(true);
  };

  const addNewCandidate = async (e) => {
    e.preventDefault();
    const data = {
      ...candidate,
    };
    try {
      const newCandidate = await axios.post(address + "/candidates/", {
        candidate_data: data,
      });
      setCandidate(newCandidate.data);
      setAdded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewCampaign = async (e) => {
    e.preventDefault();
    let candidateToUse;
    if (invalid) {
      const dupeCandidateIndex = candidates.findIndex(
        (can) => can.name.toUpperCase() === candidate.name.toUpperCase()
      );
      candidateToUse = candidates[dupeCandidateIndex];
      delete candidateToUse.campaigns;
    } else {
      candidateToUse = candidate;
    }
    const data = {
      ...candidateToUse,
      campaigns: {
        create: {
          type: `${campaign.locale ? `${campaign.locale}-` : ""}${
            campaign.type ? campaign.type : ""
          } ${campaign.year ? campaign.year : ""}`,
        },
      },
    };
    try {
      await axios.put(address + "/candidates/" + candidateToUse.id, {
        candidate_data: data,
      });
      const foundCandidate = await axios.get(
        address + "/candidates/" + candidateToUse.id
      );
      dispatch(setCandidates({ value: foundCandidate.data }));
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCandidate = async (e) => {
    e.preventDefault();
    let candidateToUse;
    if (invalid) {
      const dupeCandidateIndex = candidates.findIndex(
        (can) => can.name.toUpperCase() === candidate.name.toUpperCase()
      );
      candidateToUse = candidates[dupeCandidateIndex];
      delete candidateToUse.campaigns;
    } else {
      candidateToUse = candidate;
    }
    try {
      await axios.delete(address + "/candidates/" + candidateToUse.id);
      dispatch(removeCandidate(candidateToUse.id));
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`btn btn-${btnVariant} ${smallBtn ? "btn-sm" : ""}`}
        onClick={handleShow}
        style={{ marginRight: "1vw" }}
      >
        New Candidate
      </div>
      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">Add New Candidate and/or Campaign</h4>
        </Modal.Header>
        <div className="modal-body">
          <form>
            <div className="row align-items-center">
              <div
                className={
                  candidate && candidate.name ? "col-md-8" : "col-md-12"
                }
              >
                <FloatingLabel label={`Candidate Name:`} className="mb-3">
                  <input
                    className="form-control"
                    name="name"
                    placeholder="Candidate Name"
                    value={candidate.name}
                    onChange={(e) =>
                      onChangeCandidate({
                        field: "name",
                        value: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </div>
              {candidate && candidate.name && !invalid ? (
                <div className="col-md-4">
                  {added ? (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={(e) => deleteCandidate(e)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} /> Delete
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={(e) => addNewCandidate(e)}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} /> Create New
                    </button>
                  )}
                </div>
              ) : (
                <div className="col-md-4">
                  {invalid ? (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={(e) => deleteCandidate(e)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} /> Delete
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            {added || invalid ? (
              <div>
                <div className="mb-3">
                  <FloatingLabel label={`Type of campaign:`} className="mb-3">
                    <select
                      class="form-select"
                      aria-label="Select a campaign size..."
                      onChange={(e) =>
                        onChangeCampaign({
                          field: "locale",
                          value: e.target.value,
                        })
                      }
                      value={campaign.locale}
                    >
                      <option></option>
                      {campaignTypes.map((item) => (
                        <option key={item.locale}>{item.locale}</option>
                      ))}
                    </select>
                  </FloatingLabel>
                </div>

                {campaign && campaign.locale ? (
                  <>
                    <div className="mb-3">
                      <FloatingLabel
                        label={`Type of campaign:`}
                        className="mb-3"
                      >
                        <select
                          class="form-select"
                          placeholder="Type of campaign..."
                          onChange={(e) =>
                            onChangeCampaign({
                              field: "type",
                              value: e.target.value,
                            })
                          }
                          value={campaign.type}
                        >
                          <option></option>
                          {campaignTypes
                            .find((obj) => obj.locale === campaign.locale)
                            .types.map((i) => (
                              <option key={i}>{i}</option>
                            ))}
                        </select>
                      </FloatingLabel>
                    </div>
                    <div className="mb-3">
                      <FloatingLabel label={`Year:`} className="mb-3">
                        <input
                          className="form-control"
                          name="year"
                          placeholder="Year:"
                          value={campaign.year}
                          onChange={(e) =>
                            onChangeCampaign({
                              field: "year",
                              value: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </form>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="col ">
              <div
                className="btn btn-primary ml-auto p-2"
                onClick={(e) => addNewCampaign(e)}
              >
                Add Candidate
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
