import {
  SET_CALL_TIMES,
  SET_CALL_TIME,
  DELETE_CALL_TIME,
  SET_CALL_PHONE,
  ADD_CALL_TO_CALL_TIME,
  SET_OUTCOME_TYPES,
  ADD_NEW_ENTITY_TO_CALL_TIME,
  DELETE_ENTITY_FROM_CALL_TIME,
} from "./types";

export const setCallTimes = (obj) => {
  return {
    type: SET_CALL_TIMES,
    payload: obj,
  };
};

export const setCallTime = (obj) => {
  return {
    type: SET_CALL_TIME,
    payload: obj,
  };
};

export const deleteCallTime = (obj) => {
  return {
    type: DELETE_CALL_TIME,
    payload: obj,
  };
};

export const setCallPhone = (obj) => {
  return {
    type: SET_CALL_PHONE,
    payload: obj,
  };
};

export const setCall = (obj) => {
  return {
    type: ADD_CALL_TO_CALL_TIME,
    payload: obj,
  };
};

export const setOutcomeTypes = (obj) => {
  return {
    type: SET_OUTCOME_TYPES,
    payload: obj,
  };
};

export const addEntityToCallTime = (obj) => {
  return {
    type: ADD_NEW_ENTITY_TO_CALL_TIME,
    payload: obj,
  };
};

export const removeEntityFromCallTime = (obj) => {
  return {
    type: DELETE_ENTITY_FROM_CALL_TIME,
    payload: obj,
  };
};
