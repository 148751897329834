import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Logo from "../../assets/G3Logo.png"
import { loginUser } from "../../actions/authActions";


class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/home");
    }
  }
/*
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard"); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }*/
  shouldComponentUpdate(nextProps, nextState){
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/home"); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      nextState.errors = nextProps.errors;
    }
    return true;
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <main style={{ height: "75vh", minWidth: 360 }}>
          <Container style={{ marginTop:100 }} >
            <Card>
              <Card.Body>
                <img src={Logo} alt='Good Government Group' style={{
                  height:120,
                  marginTop:7,
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto' }}/>
                <Col md={{ span:8 }}>
                      <Card.Title>
                      Login
                      </Card.Title>
                      {/*<Card.Text>
                        Don't have an account? <Button variant="link" onClick={()=>this.props.history.push('/register')}>Register</Button>
                      </Card.Text>*/}
                  <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Form.Control
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="email"
                            placeholder="Email"
                            className={classnames("", {
                              invalid: errors.email || errors.emailnotfound
                            })}
                          />
                              <span className="text-danger">
                                {errors.email}
                                {errors.emailnotfound}
                              </span>
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                          onChange={this.onChange}
                          value={this.state.password}
                          error={errors.password}
                          id="password"
                          type="password"
                          placeholder="Password"
                          className={classnames("", {
                            invalid: errors.password || errors.passwordincorrect
                          })}
                        />
                            <span className="text-danger">
                              {errors.password}
                              {errors.passwordincorrect}
                            </span>
                    </Form.Group>
                      <Button
                        variant='primary'
                        style={{
                          width: "150px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          marginTop: "1rem"
                        }}
                        type="submit"
                      >
                        Login
                    </Button>
                  </Form>
                </Col>
              </Card.Body>
            </Card>
          </Container>
        </main>
      </>

    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
