const searchTypes = [
  {
    field: "name",
    text: "Contact Name",
  },
  {
    field: "employer",
    text: "Employer",
  },
  {
    field: "occupation",
    text: "Occupation",
  },
  {
    field: "firstName",
    text: "First Name",
  },
  {
    field: "lastName",
    text: "Last Name",
  },
];

const Search = ({ search, setSearch, onSearch }) => {
  return (
    <div className="container-fluid">
      <div className="card">
        <form onSubmit={onSearch}>
          <h5 className="card-header text-left"> Search Contacts</h5>
          <div className="card-body">
            <div className="row">
              {searchTypes.map((type) => (
                <div className="col-md-6">
                  <div className="form-floating mb-1">
                    <input
                      className="form-control form-control-sm"
                      id={"floating" + type.field}
                      value={search[type.field]}
                      placeholder={type.field}
                      onChange={(e) =>
                        setSearch({
                          ...search,
                          [[type.field]]: e.target.value,
                        })
                      }
                    />
                    <label for={"floating" + type.field}>{type.text}</label>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={onSearch}
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;
