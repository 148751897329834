import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { toTitleCase } from "../../utils/toTitleCase";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { addNewEntityToContact } from "../../actions/queryActions";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";

function AddButtonModal({
  field,
  showFieldName = true,
  smallBtn = false,
  staticTypes = false,
}) {
  const [show, setShow] = useState(false);
  const [allTypes, setTypes] = useState([]);
  const [type, setType] = useState({
    id: "",
    type: "",
  });
  const [candidate, setCandidate] = useState({
    candidateId: "",
    candidate: "",
  });
  const [campaign, setCampaign] = useState({
    campaignId: "",
    campaign: "",
  });
  const [itemToAdd, setItemToAdd] = useState({});

  const contact = useSelector((state) => state.gets.contact);
  const candidates = useSelector((state) => state.gets.candidates);
  const dispatch = useDispatch();

  const handleClose = () => {
    setItemToAdd({});
    setType("");
    setShow(false);
  };
  const handleShow = async (e) => {
    if (!staticTypes) {
      getTypes(e);
    } else {
      setTypes(staticTypes);
    }
    setShow(true);
  };

  useEffect(() => {
    if (field === "Phone") {
      setItemProp({ name: "number", value: "" });
    }
    if (field === "Email") {
      setItemProp({ name: "email", value: "" });
    }
  }, [field]);

  useEffect(() => {
    setCampaigns();
  }, [candidate]);

  const setCampaigns = () => {
    if (candidate && candidate.candidateId !== "" && !candidate.campaigns) {
      const candidateIndex = candidates.findIndex(
        (i) => i.id === candidate.candidateId
      );
      const campaigns = candidates[candidateIndex].campaigns;
      setCandidate({ ...candidate, campaigns: campaigns });
    } else {
      setCampaign({ campaignId: "", campaign: "" });
    }
  };

  const setItemProp = (e) => {
    if (e.target) {
      setItemToAdd({ ...itemToAdd, [[e.target.name]]: e.target.value });
    } else {
      setItemToAdd({ ...itemToAdd, [[e.name]]: e.value });
    }
  };

  const getTypes = async (e) => {
    e.preventDefault();
    let route;
    if (field === "Address") {
      route = "addresses/type";
    } else {
      route = `${field.toLowerCase()}s/type`;
    }
    try {
      const types = await axios.get(`${address}/${route}`);
      setTypes(types.data);
    } catch (err) {
      console.log(err);
    }
  };

  const addToContact = async (e) => {
    e.preventDefault();
    let route;
    if (field === "Address") {
      route = "addresses";
    } else {
      route = `${field.toLowerCase()}s`;
    }
    let data;
    if (contact && type && itemToAdd) {
      if (
        candidate &&
        candidate.candidateId !== "" &&
        campaign &&
        campaign.campaignId !== ""
      ) {
        data = {
          contactId: contact.id,
          type: type.type,
          ...itemToAdd,
          candidateId: candidate.candidateId,
          campaignId: campaign.campaignId,
        };
        console.log(data);
      } else {
        data = {
          contactId: contact.id,
          [[`${field.toLowerCase()}TypeId`]]: type.id,
          ...itemToAdd,
        };
      }

      try {
        const added = await axios.post(`${address}/${route}`, {
          [[`${field.toLowerCase()}_data`]]: data,
        });
        dispatch(
          addNewEntityToContact({
            field: `${route}`,
            value: added.data,
          })
        );
        handleClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      handleClose();
    }
  };

  return (
    <>
      <div
        className={`btn btn-success ${smallBtn ? "btn-sm" : ""}`}
        onClick={handleShow}
      >
        {showFieldName ? <>Add {field} </> : " "}
        <FontAwesomeIcon icon={faPlusCircle} style={{ padding: "0px" }} />
      </div>
      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as="h4">Add {field} to contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.FloatingLabel
              label={`Select a ${field.toLowerCase()} type to add:`}
              className="mb-3"
            >
              <Form.Select
                value={type.type}
                onChange={(e) => {
                  const index = e.target.selectedIndex;
                  const optionElement = e.target.childNodes[index];
                  const typeId = optionElement.getAttribute("dataId");
                  setType({ id: typeId, type: e.target.value });
                }}
              >
                {allTypes && allTypes.length > 0 ? (
                  <>
                    <option>{""}</option>
                    {allTypes.map((type) => {
                      return (
                        <option dataId={type.id} key={type.id}>
                          {toTitleCase(type.type)}
                        </option>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <option>{""}</option>
                  </>
                )}
              </Form.Select>
            </Form.FloatingLabel>
          </Form.Group>
          <>
            {
              {
                Phone: (
                  <Form.FloatingLabel className="mb-3" label={`Phone Number:`}>
                    <Form.Control
                      value={formatPhoneNumber(itemToAdd.number)}
                      name={"number"}
                      onChange={(e) => setItemProp(e)}
                    />
                  </Form.FloatingLabel>
                ),
                Email: (
                  <Form.FloatingLabel className="mb-3" label={`Email:`}>
                    <Form.Control
                      value={itemToAdd.email}
                      name={"email"}
                      onChange={(e) => setItemProp(e)}
                    />
                  </Form.FloatingLabel>
                ),
                Address: (
                  <>
                    <Form.FloatingLabel className="mb-3" label={`Address:`}>
                      <Form.Control
                        value={itemToAdd.address}
                        name={"address"}
                        onChange={(e) => setItemProp(e)}
                      />
                    </Form.FloatingLabel>
                    <Form.FloatingLabel className="mb-3" label={`City:`}>
                      <Form.Control
                        value={itemToAdd.city}
                        name={"city"}
                        onChange={(e) => setItemProp(e)}
                      />
                    </Form.FloatingLabel>
                    <Form.FloatingLabel className="mb-3" label={`State:`}>
                      <Form.Control
                        value={itemToAdd.state}
                        name={"state"}
                        onChange={(e) => setItemProp(e)}
                      />
                    </Form.FloatingLabel>
                    <Form.FloatingLabel className="mb-3" label={`Zip:`}>
                      <Form.Control
                        value={itemToAdd.zip}
                        name={"zip"}
                        onChange={(e) => setItemProp(e)}
                      />
                    </Form.FloatingLabel>
                  </>
                ),
                Donation: (
                  <>
                    <Form.FloatingLabel className="mb-3" label={`Amount:`}>
                      <Form.Control
                        value={itemToAdd.amount}
                        name={"amount"}
                        onChange={(e) => setItemProp(e)}
                      />
                    </Form.FloatingLabel>
                    <div className="mb-3">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Choose a donation date:"
                          value={itemToAdd.date}
                          onChange={(value) =>
                            setItemProp({ name: "date", value: value })
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <Form.Group>
                      <Form.FloatingLabel
                        label={`Select a candidate:`}
                        className="mb-3"
                      >
                        <Form.Select
                          value={candidate.candidate}
                          onChange={(e) => {
                            const index = e.target.selectedIndex;
                            const optionElement = e.target.childNodes[index];
                            const candidateId =
                              optionElement.getAttribute("dataId");
                            setCandidate({
                              candidateId: candidateId,
                              candidate: e.target.value,
                            });
                          }}
                        >
                          {candidates && candidates.length > 0 ? (
                            <>
                              <option>{""}</option>
                              {candidates.map((c) => {
                                return (
                                  <option dataId={c.id} key={c.id}>
                                    {toTitleCase(c.name)}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <option>{""}</option>
                            </>
                          )}
                        </Form.Select>
                      </Form.FloatingLabel>
                    </Form.Group>
                    <Form.Group>
                      {candidate && candidate.campaigns ? (
                        <>
                          <Form.FloatingLabel
                            label={`Select a Campaign:`}
                            className="mb-3"
                          >
                            <Form.Select
                              value={campaign.campaign}
                              onChange={(e) => {
                                const index = e.target.selectedIndex;
                                const optionElement =
                                  e.target.childNodes[index];
                                const candidateId =
                                  optionElement.getAttribute("dataId");
                                setCampaign({
                                  campaignId: candidateId,
                                  campaign:
                                    e.target.value === "None"
                                      ? null
                                      : e.target.value,
                                });
                              }}
                            >
                              {candidate &&
                              candidate.campaigns &&
                              candidate.campaigns.length > 0 ? (
                                <>
                                  <option>{""}</option>
                                  {candidate.campaigns.map((c) => {
                                    return (
                                      <option dataId={c.id} key={c.id}>
                                        {c.type ? toTitleCase(c.type) : "None"}
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <option>{""}</option>
                                </>
                              )}
                            </Form.Select>
                          </Form.FloatingLabel>
                        </>
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                  </>
                ),
              }[field]
            }
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col ">
              <div
                className="btn btn-primary ml-auto p-2"
                onClick={(e) => addToContact(e)}
              >
                Add {field}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddButtonModal;
