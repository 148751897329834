import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { setLists, setList } from "../../actions/queryActions";

import { address } from "../../utils/connectionAddress";
import { useDispatch } from "react-redux";
import ChooseListComponent from "../BuildingBlocks/ChooseListComponent";

function ChooseList() {
  const [show, setShow] = useState(false);
  const [newList, setNewList] = useState({
    global: false,
    name: "",
  });
  const [key, setKey] = useState("choose");
  const userId = useSelector((state) => state.auth.user.id);
  const lists = useSelector((state) => state.gets.lists);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (lists && lists.length === 0) {
      setKey("create");
    } else {
      setKey("choose");
    }
  }, [lists]);

  const addNewList = async () => {
    const data = {
      ...newList,
      userId: userId,
    };
    const createdList = await axios.post(`${address}/lists`, {
      list_data: data,
    });
    dispatch(setLists({ value: createdList.data }));
    dispatch(setList(createdList.data));
    setNewList({
      global: false,
      name: "",
    });
    setShow(false);
  };

  return (
    <>
      <Button
        variant="outline-light"
        className="float-right"
        onClick={handleShow}
      >
        Manage List
      </Button>
      <Modal
        style={{
          backgroundColor: "transparent",
          maxHeight: "100%",
          minWidth: 360,
        }}
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title>Choose/make a different list</Modal.Title>
          <br />
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab eventKey="choose" title="Choose Existing">
              <Form style={{ paddingTop: "5px" }}>
                <ChooseListComponent
                  lists={lists}
                  setShow={setShow}
                  label={"Choose a list:"}
                  dynamicSet={false}
                />
              </Form>
              <br />
            </Tab>
            <Tab eventKey="create" title="Create New">
              <Form style={{ paddingTop: "5px" }}>
                <Row>
                  <Col>
                    <Form.Label htmlFor="listGlobal">
                      Add a new list:
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Check
                      className="float-right"
                      type="checkbox"
                      name="listGlobal"
                      id={`default-checkbox`}
                      label={`Make it Global?`}
                      checked={newList.global}
                      onChange={() =>
                        setNewList({ ...newList, global: !newList.global })
                      }
                    />
                  </Col>
                </Row>
                <Form.Control
                  value={newList.name}
                  name="name"
                  type="text"
                  placeholder="Name of new list"
                  onChange={(e) =>
                    setNewList({ ...newList, name: e.target.value })
                  }
                />
              </Form>
              <br />
              <Row>
                <Col>
                  <Button variant="primary" onClick={addNewList}>
                    Add a new list
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="outline-secondary"
                    onClick={handleClose}
                    className="float-right"
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChooseList;
