import React, { useEffect, useState } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";
import { useDispatch, useSelector } from "react-redux";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { deleteEntityFromContact } from "../../actions/queryActions";
import { removeEntityFromCallTime } from "../../actions/callTimeActions";
import DeleteEntityModal from "./DeleteEntityModal";

export default function CallsTable({ height }) {
  const [calls, setCalls] = useState();
  const contact = useSelector((state) => state.gets.contact);
  const callTime = useSelector((state) => state.calls.callTime);
  const dispatch = useDispatch();

  useEffect(() => {
    getCalls();
  }, [contact]);

  const getCalls = async () => {
    if (contact) {
      try {
        const callsFound = await axios.get(
          `${address}/calls?contact_id=${contact.id}`
        );
        setCalls(callsFound.data);
      } catch (err) {
        console.log(err);
        setCalls();
      }
    }
  };

  const deleteCall = async (e) => {
    e.preventDefault();
    const id = e.target.id;
    console.log(id);
    if (id) {
      try {
        axios.delete(`${address}/calls/${id}`);
        dispatch(deleteEntityFromContact({ field: "calls", id: id }));
        if (callTime) {
          dispatch(removeEntityFromCallTime({ field: "calls", id: id }));
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  };

  return (
    <>
      {calls && calls.length > 0 ? (
        <>
          <div
            className="table-responsive"
            style={{ overflow: "auto", maxHeight: `${height}` }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Date of Call</th>
                  <th>Candidate</th>
                  <th>Phone Number</th>
                  <th>Outcome</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {calls.map((call) => (
                  <>
                    <tr className="align-items-center">
                      <td>{new Date(call.createdAt).toDateString()}</td>
                      <td>{call.candidate.name}</td>
                      <td>{formatPhoneNumber(call.phone.number)}</td>
                      <td>{call.outcome.value}</td>
                      <td>{call.notes}</td>
                      <td id={call.id}>
                        <DeleteEntityModal id={call.id} field={"Call"} />
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div
            className="table-responsive"
            style={{ overflow: "auto", maxHeight: `${height}` }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Date of Call</th>
                  <th>Candidate</th>
                  <th>Phone Number</th>
                  <th>Outcome</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="100%" className="text-center">
                    No Calls Found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
