import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { address } from "../../utils/connectionAddress";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import {
  addNewEntityToContact,
  removeContactFromList,
  setContact,
} from "../../actions/queryActions";
import {
  faChevronLeft,
  faChevronRight,
  faSave,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addEntityToCallTime,
  removeEntityFromCallTime,
  setCall,
  setCallPhone,
  setOutcomeTypes,
} from "../../actions/callTimeActions";

export const PostCalls = () => {
  const [outcome, setOutcome] = useState({});
  const [callNotes, setCallNotes] = useState("");

  const outcomes = useSelector((state) => state.calls.outcomes);
  const contact = useSelector((state) => state.gets.contact);
  const phone = useSelector((state) => state.calls.phone);
  const user = useSelector((state) => state.auth.user);
  const list = useSelector((state) => state.gets.list);
  const callTime = useSelector((state) => state.calls.callTime);
  const dispatch = useDispatch();

  useEffect(() => {
    if (outcomes && outcomes.length === 0) {
      getOutcomes();
    }
    dispatch(setCallPhone(contact.phones[0]));
  }, [contact, outcomes]);

  const getOutcomes = async () => {
    try {
      const outcomesFound = await axios.get(address + "/call_times/outcomes");
      dispatch(setOutcomeTypes({ value: outcomesFound.data }));
    } catch (err) {
      console.log(err);
    }
  };

  const onOutcomeChange = (e) => {
    const index = outcomes.findIndex((outcome) => outcome.id === e.target.id);
    setOutcome(outcomes[index]);
  };

  const moveContacts = async (forwardOrBack) => {
    const indexCurrent = list.contacts.findIndex((id) => {
      return id === contact.id;
    });
    // Forward or Back is forward when true and back when false
    const newIndex = forwardOrBack ? indexCurrent + 1 : indexCurrent - 1;
    try {
      const nextContact = await axios.get(
        address + `/contacts/${list.contacts[newIndex]}`
      );
      dispatch(setContact(nextContact.data));
      setCallNotes("");
    } catch (err) {
      console.log(err);
    }
  };

  const removeFromList = async (e) => {
    e.preventDefault();
    const indexCurrent = list.contacts.findIndex((id) => {
      return id === contact.id;
    });
    try {
      await axios.delete(`${address}/lists/${list.id}/contacts/${contact.id}`);
      if (indexCurrent + 1 === list.contacts.length) moveContacts(false);
      if (indexCurrent === 0) moveContacts(true);
      else moveContacts(true);
      dispatch(removeContactFromList(contact.id));
    } catch (err) {
      console.log(err);
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    const call = {
      candidateId: callTime.candidateId,
      contactId: contact.id,
      phoneId: phone.id,
      outcomeId: outcome.id,
      notes: callNotes,
      userId: user.id,
    };
    try {
      const savedCall = await axios.post(
        address + `/call_times/${callTime.id}/calls`,
        { call_data: call }
      );
      dispatch(setCall(savedCall.data));
      dispatch(
        addNewEntityToContact({ field: "calls", value: savedCall.data })
      );
      setCallNotes("");
      const indexCurrent = list.contacts.findIndex((id) => {
        return id === contact.id;
      });
      if (indexCurrent + 1 === list.contacts.length) {
        return;
      } else {
        moveContacts(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="card" style={{ marginBottom: "2vh" }}>
      <div className="text-center">
        <div className="alert alert-primary">
          <div className="container-fluid" style={{ width: "100%" }}>
            <div className="row">
              <div className="col-md-2">
                <div
                  className={`btn btn-primary ${
                    list.contacts.findIndex((id) => id === contact.id) === 0
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => moveContacts(false)}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                </div>
              </div>
              <div className="col-md-8">
                <h4>{contact ? contact.name : ""}</h4>
              </div>
              <div className="col-md-2">
                <div
                  className={`btn btn-primary ${
                    list.contacts.findIndex((id) => id === contact.id) + 1 ===
                    list.contacts.length
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => moveContacts(true)}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="xs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h5 className="font-weight-bold">
              {phone && phone.number ? (
                <>
                  {`Phone Number Logging Result for: ${formatPhoneNumber(
                    phone.number
                  )}
                  (${phone.type.type})`}
                  <div
                    className="btn btn-danger btn-sm"
                    style={{ marginLeft: "1vw" }}
                    onClick={(e) => removeFromList(e)}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </div>
                </>
              ) : (
                <>
                  No phone number for this contact
                  <div
                    className="btn btn-danger btn-sm"
                    style={{ marginLeft: "1vw" }}
                    onClick={(e) => removeFromList(e)}
                  >
                    Remove Contact From List
                  </div>
                </>
              )}{" "}
            </h5>
          </div>
          <div className="col-md-4"></div>
        </div>
        <hr />
        <OutcomesControl
          outcomes={outcomes}
          onOutcomeChange={onOutcomeChange}
        />
        <form>
          <div className="row" style={{ marginBottom: "2vh" }}>
            <div className="col">
              <div className="form-group">
                <label for="phoneNumber">Call Notes:</label>
                <textarea
                  className="form-control"
                  name="callNotes"
                  value={callNotes}
                  onChange={(e) => setCallNotes(e.target.value)}
                  placeholder="Notes..."
                  rows="4"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <PledgesControl />
            </div>
            <div className="col-md-2" />
            <div className="col-md-2">
              <div className="btn btn-primary" onClick={(e) => onSave(e)}>
                Save <FontAwesomeIcon icon={faSave} />
              </div>
            </div>
            {/* <div className="col-md-2">
              <button type="button" className="btn btn-info">
                Send Follow Up
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export const PledgesControl = () => {
  const [pledge, setPledge] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const callTime = useSelector((state) => state.calls.callTime);
  const contact = useSelector((state) => state.gets.contact);

  useEffect(() => {
    if (callTime && callTime.pledges) {
      const index = contact.pledges.findIndex(
        (i) => i.callTimeId === callTime.id
      );
      setPledge(contact.pledges[index]);
    }
    setEditing(false);
  }, [contact]);

  const createPledge = async (e) => {
    e.preventDefault();
    const pledgeData = {
      callTimeId: callTime.id,
      contactId: contact.id,
      candidateId: callTime.candidateId,
    };
    try {
      const createdPledge = await axios.post(address + "/pledges", {
        pledge_data: pledgeData,
      });
      createdPledge.data.amount = 0;
      setEditing(true);
      setPledge(createdPledge.data);
    } catch (err) {
      console.log(err);
    }
  };

  const savePledge = async (e) => {
    e.preventDefault();
    try {
      const editedPledge = await axios.put(address + `/pledges/${pledge.id}`, {
        pledge_data: pledge,
      });
      dispatch(
        addEntityToCallTime({
          field: "pledges",
          value: editedPledge.data,
        })
      );
      setPledge(editedPledge.data);
      setEditing(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deletePledge = async (e) => {
    e.preventDefault();
    try {
      const deletedPledge = await axios.delete(
        address + `/pledges/${pledge.id}`
      );
      // REDUX UPDATE GOES HERE
      dispatch(
        removeEntityFromCallTime({
          field: "pledges",
          id: deletedPledge.data,
        })
      );
      setPledge(false);
      setEditing(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container-fluid">
      {pledge && pledge.id ? (
        <div className="row">
          {editing ? (
            <>
              <div className="col-md-2">
                <input
                  type="number"
                  class="form-control"
                  value={pledge.amount}
                  onChange={(e) =>
                    setPledge({ ...pledge, amount: parseInt(e.target.value) })
                  }
                />
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-success btn-sm"
                  onClick={(e) => savePledge(e)}
                >
                  Save Pledge
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => deletePledge(e)}
                >
                  Delete Pledge
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col">Pledged: ${pledge.amount}</div>
              <div className="col">
                <button
                  className="btn btn-outline-warning btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditing(true);
                  }}
                >
                  Edit Pledge
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => deletePledge(e)}
                >
                  Delete Pledge
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <button
            className="btn btn-outline-success btn-sm"
            onClick={(e) => createPledge(e)}
          >
            Add Pledge
          </button>
        </>
      )}
    </div>
  );
};

export const OutcomesControl = ({ outcomes, onOutcomeChange }) => {
  return (
    <div className="row" style={{ marginBottom: "1vh" }}>
      {outcomes && outcomes.length > 0 ? (
        <>
          {outcomes.map((outcome) => {
            let btnType;
            switch (outcome.value) {
              case "Bad Number":
              case "Wrong Number":
              case "No/Refused":
                btnType = "danger";
                break;
              case "Call Back":
              case "Disconnected":
                btnType = "warning";
                break;
              case "Spoke":
                btnType = "success";
                break;
              default:
                btnType = "primary";
                break;
            }
            return (
              <div className="col">
                <input
                  type="radio"
                  className="btn-check "
                  name="outcomes"
                  id={`${outcome.id}`}
                  onClick={(e) => onOutcomeChange(e)}
                />
                <label
                  className={`btn btn-sm btn-outline-${
                    btnType ? btnType : "primary"
                  }`}
                  for={`${outcome.id}`}
                  style={{ fontSize: ".6vmax" }}
                >
                  {outcome.value}
                </label>
              </div>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
