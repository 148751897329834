import {
  SET_CONTACT,
  SET_LIST,
  DELETE_LIST,
  SET_LISTS,
  ADD_CONTACT_TO_LIST,
  REMOVE_CONTACT_FROM_LIST,
  SET_CONTACT_PROPERTY_BASIC,
  SET_CONTACT_PROPERTY_RELATION,
  ADD_NEW_ENTITY_TO_CONTACT,
  DELETE_ENTITY_FROM_CONTACT,
  CLEAR_RESOURCE,
  SET_CANDIDATES,
  DELETE_CANDIDATE,
} from "../actions/types";

const initialState = {
  contact: false,
  lists: [],
  list: false,
  candidates: [],
};

const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const setResource = (field, value) => {
  const fieldToUpdate = field;
  if (Array.isArray(value)) {
    fieldToUpdate.push(...value);
  } else {
    fieldToUpdate.push(value);
  }
  const deduped = getUniqueListBy(fieldToUpdate, "id");
  return deduped;
};

function queryReducer(state = initialState, action) {
  const { contact, lists, list, candidates } = state;
  let fieldToUpdate, index, resource, indexToPop;
  switch (action.type) {
    case SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case ADD_CONTACT_TO_LIST:
      fieldToUpdate = list.contacts;
      fieldToUpdate.push(action.payload);
      fieldToUpdate = [...new Set(fieldToUpdate)];
      return {
        ...state,
        list: {
          ...list,
          contacts: [...fieldToUpdate],
        },
      };
    case REMOVE_CONTACT_FROM_LIST:
      fieldToUpdate = list.contacts;
      index = fieldToUpdate.findIndex((i) => i === action.payload);
      fieldToUpdate.splice(index, 1);
      return {
        ...state,
        list: {
          ...list,
          contacts: [...fieldToUpdate],
        },
      };
    case SET_LISTS:
      resource = setResource(lists, action.payload.value);
      return {
        ...state,
        lists: [...resource],
      };
    case DELETE_LIST:
      fieldToUpdate = lists;
      indexToPop = fieldToUpdate.findIndex((i) => i.id === action.payload);
      fieldToUpdate.splice(indexToPop, 1);
      return {
        ...state,
        lists: [...fieldToUpdate],
      };
    case SET_CONTACT_PROPERTY_BASIC:
      return {
        ...state,
        contact: {
          ...contact,
          [[action.payload.field]]: action.payload.value,
        },
      };
    case SET_CONTACT_PROPERTY_RELATION:
      fieldToUpdate = contact[action.payload.field];
      index = fieldToUpdate.findIndex((obj) => obj.id === action.payload.id);
      fieldToUpdate[index][action.payload.subfield] = action.payload.value;
      return {
        ...state,
        contact: {
          ...contact,
          [[action.payload.field]]: fieldToUpdate,
        },
      };
    case ADD_NEW_ENTITY_TO_CONTACT:
      fieldToUpdate = contact[action.payload.field];
      fieldToUpdate.push(action.payload.value);
      return {
        ...state,
        contact: {
          ...contact,
          [[action.payload.field]]: [...fieldToUpdate],
        },
      };
    case DELETE_ENTITY_FROM_CONTACT:
      fieldToUpdate = contact[action.payload.field];
      indexToPop = fieldToUpdate.findIndex((i) => i.id === action.payload.id);
      if (indexToPop !== -1) {
        fieldToUpdate.splice(indexToPop, 1);
      }
      return {
        ...state,
        contact: {
          ...contact,
          [[action.payload.field]]: [...fieldToUpdate],
        },
      };
    case CLEAR_RESOURCE:
      return {
        ...state,
        [[action.payload.field]]: undefined,
      };
    case SET_CANDIDATES:
      resource = setResource(candidates, action.payload.value);
      return {
        ...state,
        candidates: [...resource],
      };
    case DELETE_CANDIDATE:
      fieldToUpdate = candidates;
      indexToPop = fieldToUpdate.findIndex((i) => i.id === action.payload);
      fieldToUpdate.splice(indexToPop, 1);
      return {
        ...state,
        candidates: [...fieldToUpdate],
      };
    default:
      return state;
  }
}
export default queryReducer;
