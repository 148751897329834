import React, { useState } from "react";
import axios from "axios";
import { address } from "../../utils/connectionAddress";

import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QuickFind = ({ setPropCallback, multi = true, icon }) => {
  const [selectedOption, setSelectedOption] = useState();

  const promiseOptions = async (inputValue) => {
    try {
      const results = await axios.get(address + "/contacts?name=" + inputValue);
      return results.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row text-black">
      {console.log(selectedOption)}
      <div className="col-md-10">
        {multi ? (
          <AsyncSelect
            cacheOptions
            isMulti
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            selectedOption={selectedOption}
            value={selectedOption}
            onChange={setSelectedOption}
            loadOptions={promiseOptions}
          />
        ) : (
          <AsyncSelect
            cacheOptions
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            selectedOption={selectedOption}
            value={selectedOption}
            onChange={setSelectedOption}
            loadOptions={promiseOptions}
          />
        )}
      </div>
      <div className="col-md-2">
        <button
          onClick={(e) => {
            if (selectedOption) {
              setPropCallback(e, selectedOption);
              setSelectedOption(null);
            } else return;
          }}
          className="btn btn-success btn-small"
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      </div>
    </div>
  );
};
