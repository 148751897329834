import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearContact, clearList, setLists } from "../../actions/queryActions";
import { address } from "../../utils/connectionAddress";
import NavbarMain from "../Navbar";
import { Calendar, Views } from "react-big-calendar";
import { localizer } from "../../utils/localizer";
import "react-big-calendar/lib/sass/styles.scss";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { setCallTimes, deleteCallTime } from "../../actions/callTimeActions";
import { setCandidates } from "../../actions/candidateActions";
import ChooseListComponent from "../BuildingBlocks/ChooseListComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ResuableBreadcrumbs } from "../BuildingBlocks/Breadcrumbs";

function useCallTimeLanding(props) {
  const lists = useSelector((state) => state.gets.lists);
  const user = useSelector((state) => state.auth.user);
  const callTimes = useSelector((state) => state.calls.callTimes);
  const candidates = useSelector((state) => state.gets.candidates);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearList());
    dispatch(clearContact());
    if (lists && lists.length === 0) {
      getLists();
    }
    if (callTimes && callTimes.length === 0) {
      getCallTimes();
    }
    if (candidates && candidates.length === 0) {
      getCandidates();
    }
  }, []);

  const getCandidates = async () => {
    try {
      const candidates = await axios.get(`${address}/candidates`);
      dispatch(setCandidates({ value: candidates.data }));
    } catch (err) {
      console.log(err);
    }
  };

  const getLists = async () => {
    let userId = user.id;
    try {
      const listsFound = await axios.get(`${address}/lists?userId=${userId}`);
      const globalListsFound = await axios.get(
        `${address}/lists?global=${true}`
      );
      dispatch(setLists({ value: listsFound.data }));
      dispatch(setLists({ value: globalListsFound.data }));
    } catch (err) {
      console.log(err);
    }
  };

  const getCallTimes = async () => {
    try {
      const ctsFound = await axios.get(`${address}/call_times`);
      const ctsMapped = ctsFound.data.map((ct) => {
        const { start, end, ...rest } = ct;
        console.log(rest);
        return {
          ...rest,
          start: new Date(start),
          end: new Date(end),
          title: ct.candidate.name,
        };
      });
      console.log(ctsMapped);
      dispatch(setCallTimes({ value: ctsMapped }));
    } catch (err) {
      console.log(err);
    }
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };
  return (
    <div>
      <NavbarMain onClick={onLogoutClick} />
      <div className="container-fluid">
        <div
          style={{ height: "5vh", marginTop: "2vh" }}
          className="container-fluid"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="text-left">
                <ResuableBreadcrumbs
                  array={[
                    { value: "Schedule", active: true, href: "calltime" },
                    { value: "Call" },
                    { value: "Review" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "90vh", paddingBottom: "2vh" }}
          className="container-fluid"
        >
          <CallTimeCalendar />
        </div>
      </div>
    </div>
  );
}

const CreateEventModal = ({ event, shown, setShown }) => {
  const [show, setShow] = useState(false);
  const [candidate, setCandidate] = useState({
    id: "",
    name: "",
  });
  const list = useSelector((state) => state.gets.list);
  const lists = useSelector((state) => state.gets.lists);
  const candidates = useSelector((state) => state.gets.candidates);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setShow(shown);
  }, [shown]);

  const createCallTime = async (e) => {
    e.preventDefault();
    const data = {
      userId: user.id,
      listId: list.id,
      start: new Date(event.start),
      end: new Date(event.end),
      candidateId: candidate.id,
    };
    try {
      const createdCallTime = await axios.post(`${address}/call_times`, {
        callTime_data: data,
      });
      // REDUX CALL HERE
      createdCallTime.data.title = candidate.name;
      createdCallTime.data.end = new Date(createdCallTime.data.end);
      createdCallTime.data.start = new Date(createdCallTime.data.start);
      createdCallTime.data.desc = `Call Time with ${candidate.name} focusing on ${list.name}`;
      dispatch(setCallTimes({ value: createdCallTime.data }));
    } catch (err) {
      console.log(err);
    }
    setShown(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShown(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Call Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              Call time start:{" "}
              {event && event.start && event.end
                ? `${event.start.toDateString()} ${event.start.toTimeString()}-${event.end.toTimeString()}`
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {candidates && candidates.length > 0 ? (
                <FloatingLabel
                  label="Select a candidate to use for this call time:"
                  className="mb-3"
                >
                  <Form.Control
                    as="select"
                    name="candidate"
                    value={candidate.name}
                    onChange={(e) => {
                      const index = e.target.selectedIndex;
                      const optionElement = e.target.childNodes[index];
                      const optionElementId =
                        optionElement.getAttribute("datalistid");
                      setCandidate({
                        id: optionElementId,
                        name: e.target.value,
                      });
                    }}
                  >
                    <option>{""}</option>
                    {candidates.map((can) => {
                      return <option datalistid={can.id}>{can.name}</option>;
                    })}
                  </Form.Control>
                </FloatingLabel>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <ChooseListComponent
          label={"Select a list to use for this call time:"}
          setShow={setShow}
          lists={lists}
          dynamicSet={true}
          list={list}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="btn btn-primary" onClick={(e) => createCallTime(e)}>
          Add Call Time
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const EventInfoModal = ({ event, shown, setShown }) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setShow(shown);
  }, [shown]);

  const startCallTime = async (e) => {
    e.preventDefault();
    history.push(`/calltime/${event.id}`);
    setShown(false);
  };

  const deleteThisCallTime = async (e) => {
    e.preventDefault();
    try {
      await axios.delete(address + "/call_times/" + event.id);
      dispatch(deleteCallTime(event.id));
    } catch (err) {}
    setShown(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShown(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Call Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            Call time start:{" "}
            {event && event.start && event.end
              ? `${event.start.toDateString()} ${event.start.toTimeString()}-${event.end.toTimeString()}`
              : ""}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-sm-5">
              <button
                className="btn btn-primary"
                onClick={(e) => startCallTime(e)}
              >
                Start Call Time
              </button>
            </div>
            <div className="col-md-5 col-sm-2" />
            <div className="col-md-2 col-sm-5">
              <button
                className="btn btn-danger mr-auto"
                onClick={(e) => deleteThisCallTime(e)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

let viewsToUse = [Views.WEEK, Views.MONTH, Views.DAY, Views.AGENDA];

function Event({ event }) {
  return (
    <span>
      <strong>{event.title}</strong>
      <br />
      <em>{event.desc}</em>
    </span>
  );
}

const CallTimeCalendar = () => {
  const [showCreateCallTime, setShowCreateCallTime] = useState(false);
  const [showCallTime, setShowCallTime] = useState(false);
  const [eventProps, setProps] = useState(undefined);

  const callTimes = useSelector((state) => state.calls.callTimes);

  const eventCallback = (e, type) => {
    console.log(e);
    setProps(e);
    type === "info" ? setShowCallTime(true) : setShowCreateCallTime(true);
  };

  return (
    <>
      <Calendar
        views={viewsToUse}
        defaultView={Views.WEEK}
        events={callTimes}
        step={30}
        timeslots={4}
        defaultDate={new Date()}
        components={{
          event: Event,
        }}
        onSelectEvent={(event) => {
          eventCallback(event, "info");
        }}
        selectable
        onSelectSlot={(e) => eventCallback(e)}
        localizer={localizer}
      />
      <CreateEventModal
        event={eventProps}
        shown={showCreateCallTime}
        setShown={setShowCreateCallTime}
      />
      <EventInfoModal
        event={eventProps}
        setShown={setShowCallTime}
        shown={showCallTime}
      />
    </>
  );
};

export default useCallTimeLanding;
