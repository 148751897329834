/*
You must make a manual change in this file whenever pushing to netlify
*/

let address;
if (process.env.REACT_APP_DEPLOY_STATUS === "development") {
  address = "http://localhost:5000/api";
} else {
  address = "https://api.goodgovgroup.com/api";
}

module.exports = {
  address: address,
};
