import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { connect } from "react-redux";
import "./selectedStyling.css";
import { logoutUser } from "../../actions/authActions";
import NavbarMain from "../Navbar";
import PropTypes from "prop-types";
import ContributorCard from "./ContactCard";
import ChooseList from "./ChooseList";
import ListDataCheck from "./ListDataCheck";

import { address } from "../../utils/connectionAddress";
import Search from "./ContactSearch";
import FoundContacts from "./ContactsFound";

import {
  clearContact,
  clearList,
  setContact,
  setLists,
} from "../../actions/queryActions";
import { setCandidates } from "../../actions/candidateActions";

function HomePage(props) {
  const [contributors, setContributors] = useState();
  const [searchData, setSearchData] = useState({
    name: "",
    employer: "",
    occupation: "",
  });

  const user = useSelector((state) => state.auth.user);
  const chosen = useSelector((state) => state.gets.contact);
  const candidates = useSelector((state) => state.gets.candidates);
  const currentList = useSelector((state) => state.gets.list);

  const dispatch = useDispatch();

  useEffect(() => {
    getLists();
    dispatch(clearContact());
    dispatch(clearList());
  }, []);

  useEffect(() => {
    if (candidates && candidates.length === 0) {
      getCandidates();
    }
  }, []);

  const getCandidates = async () => {
    try {
      const candidates = await axios.get(`${address}/candidates`);
      dispatch(setCandidates({ value: candidates.data }));
    } catch (err) {
      console.log(err);
    }
  };

  const getLists = async () => {
    let userId = user.id;
    try {
      const listsFound = await axios.get(`${address}/lists?userId=${userId}`);
      const globalListsFound = await axios.get(
        `${address}/lists?global=${true}`
      );
      dispatch(setLists({ value: listsFound.data }));
      dispatch(setLists({ value: globalListsFound.data }));
    } catch (err) {
      alert(err.response.data);
    }
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  const showContributor = async (e) => {
    const id = e.target.id;
    try {
      const contactFound = await axios.get(`${address}/contacts/${id}`);
      dispatch(setContact(contactFound.data));
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = async (e) => {
    e.preventDefault();
    if (searchData) {
      const queryString = Object.keys(searchData).map((it) => {
        return `${it}=${searchData[it]}`;
      });
      try {
        const contactsFound = await axios.get(
          `${address}/contacts?${queryString.join("&")}`
        );
        setContributors(contactsFound.data);
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(setContact(undefined));
      return;
    }
  };
  return (
    <>
      <NavbarMain onClick={onLogoutClick} />
      {console.log(candidates)}
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div
              className="card align-items-center bg-secondary text-white"
              style={{ marginBottom: "2vh" }}
            >
              <div className="container-fluid">
                <div className="card-body">
                  {currentList ? (
                    <>
                      <div className="row align-items-center">
                        <div className="col">
                          <h5>Current List: {currentList.name}</h5>
                        </div>
                        <div className="col">
                          <ChooseList />
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col">
                          <p>
                            Your List Length:{" "}
                            {(currentList.contacts &&
                              currentList.contacts.length) ||
                              0}
                          </p>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col">
                          <ListDataCheck />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row align-items-center">
                        <div className="col">
                          <h5>Choose a list</h5>
                        </div>
                        <div className="col">
                          <ChooseList />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "2vh" }}>
              <Search
                setSearch={setSearchData}
                onSearch={onSearch}
                search={searchData}
              />
            </div>
            <FoundContacts
              contributors={contributors}
              showContributor={showContributor}
            />
          </div>
          <div className="col-md-8 col-sm-8" style={{ marginBottom: 20 }}>
            {chosen ? (
              <>
                <ContributorCard contact={chosen} />
              </>
            ) : (
              <div className="card">
                <h3 className="card-header text-left">Look Up a Donor</h3>
                <div className="card-body" style={{ height: "80vh" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

HomePage.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(HomePage);
